import { TextField } from "@material-ui/core";
import ArchiveIcon from "@mui/icons-material/Archive";
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import BrushIcon from "@mui/icons-material/Brush";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import SendIcon from "@mui/icons-material/Send";
import SlideshowIcon from "@mui/icons-material/Slideshow";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import { DataGrid, trTR } from "@mui/x-data-grid";
import axios from "axios";
import htmlToPdfmake from "html-to-pdfmake";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { setContracts } from "../../actions/contractActions";
import { DocumentStatus } from "../../actions/types";
import { blobToBase64, convertHtmlToPdf } from "../../util/pdf/PdfConverter";
import "./datatable.scss";
import { userColumns } from "./datatablesource";


pdfMake.vfs = pdfFonts.pdfMake.vfs;

function NoRowsOverlay() {
  return (
    <Stack height="100%" alignItems="center" justifyContent="center">
      Sözleşme Kaydı Bulunmamaktadır
    </Stack>
  );
}

const Datatable = ({ searchTerm }) => {
  const [data, setData] = useState([]);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.security.user);
  const userId = user.id;
  const [url, setUrl] = useState(null);
  const [show, setShow] = useState(false);
  const [archiveShow, setArchiveShow] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const handleArchiveClose = () => setArchiveShow(false);
  const [dialogText, setDialogText] = useState(null);
  const [deleteShow, setDeleteShow] = useState(false);
  const handleDeleteClose = () => setDeleteShow(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [signedRow, setSignedRow] = useState(null);
  const [approveShow, setApproveShow] = useState(false);
  const [rejectReason, setRejectReason] = useState(null);
  const [rejectShow, setRejectShow] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [filteredData, setFilteredData] = useState([]);
  const open = Boolean(anchorEl);
  const identityNumber =
    user.company && user.company !== null
      ? user.company.identityNumber
      : user.identityNumber;

  const ITEM_HEIGHT = 88;
  const ITEM_PADDING_TOP = 8;

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 150,
      },
    },
  };

  async function downloadFile(system) {

    let url;
    await axios
      .get(`api/sign/get-signer-download-url`)
      .then((res) => {
        if (res.data.code === "100") {
          if (system === "W")
            url = res.data.windowsUrl;
          else
            url = res.data.iosUrl;
        } else {
          //console.log(res.data);
          return;
        }
      })
      .catch((error) => {
        console.log(error);
        return;
      });

    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', ''); // İndirme işlemi başlatacak
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  async function handleApprove() {
    let contractStatus = {
      id: selectedRow.contractId,
      status: DocumentStatus.Approve,
    };
    await axios
      .post(`api/contract-query-service/update-contract-status`, contractStatus)
      .then((res) => {
        contractData(userId);
        setApproveShow(false);
      }).catch((error) => {
        console.log(error);
      });
  }

  async function handleReject() {
    let contractStatus = {
      id: selectedRow.contractId,
      status: DocumentStatus.Reject,
      rejectReason: rejectReason,
    };
    await axios
      .post(`api/contract-query-service/update-contract-status`, contractStatus)
      .then((res) => {
        contractData(userId);
        setRejectShow(false);
        setRejectReason("");
      }).catch((error) => {
        console.log(error);
      });
  }

  async function sign() {
    setAnchorEl(null);
    const signedDocument = {
      id: signedRow.contractId,
      name: signedRow.generatedId + ".pdf",
      content: "",
      directory: identityNumber,
      type: 0
    };

    await axios.get(`api/contract/get-contract/${signedRow.contractId}`).then(async (res) => {
      let htmlToPdf = res.data["contractHTMLContent"];
      console.log(htmlToPdf);
      const pdfBlob = await convertHtmlToPdf(htmlToPdf);
      console.log("BLOB : ")
      console.log(pdfBlob);
      const base = await blobToBase64(pdfBlob);
      console.log("BASE : ")
      console.log(base);

      signedDocument.content = base;
      console.log(signedDocument);
      axios
        .post("api/sign/sign-digital", signedDocument)
        .then((response) => {
          if (response.data.isSigned)
            toast.success("Doküman imzalandı")
          else {
            toast.error(response.data.userMessage);
            console.log(response.data.error);
          }
        })
        .catch((error) => {
          toast.error("Doküman imzalanırken hata oluştu.");
          console.log(error);
        });

    }).catch((error) => {
      console.log(error);
    });
  };

  async function signE() {
    setAnchorEl(null);
    const signedDocument = {
      documentId: signedRow.contractId,
      userId: userId
    };

    axios
      .post("api/sign/sign-e", signedDocument)
      .then((response) => {
        if (response.data.code === "100") toast.success("Dosya imzalama aracına yönlendirildi.Şifrenizi girerek imzalayınız");
        else {
          toast.info(
            <div>
              Digisoz İmzzacı uygulaması çalışmıyor veya giriş yapılmamış.
              <span>Eğer henüz indirmediyseniz aşağıda uygun olan uygulamayı indirin</span>
              <div style={{ marginTop: '10px' }}>
                <button style={{ marginRight: '10px' }} onClick={() => downloadFile('W')}>Windows</button>
                <button onClick={() => downloadFile('I')}>IOS</button>
              </div>
            </div>,
            {
              autoClose: false, // Otomatik kapanmasını istemiyoruz
              closeOnClick: true,
              draggable: true,
            }
          );
          console.log(response.data.error);
        }
      })
      .catch((error) => {
        toast.error("Doküman imzalanırken hata oluştu.");
        console.log(error);
      });
  }


  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
    setDialogText(null);
  };
  const handleDialogShow = (rejectReason) => {
    setDialogText(rejectReason);
    setOpenDialog(true);
  };

  async function contractData(userId) {
    await axios
      .get(`api/contract/get-all-document-by-identity/${userId}`)
      .then((res) => {
        setData(res.data);
        dispatch(setContracts(res));
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    contractData(userId);
  }, []);

  async function handleArchive(contractId) {
    await axios
      .post(`api/contract/move-contract-archive/${contractId}`)
      .then((res) => {
        contractData(userId);
        handleArchiveClose();
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function handleSendContract(contractId) {
    let contractStatus = {
      id: contractId,
      status: 2,
    };
    await axios
      .post(`api/contract/update-contract-status`, contractStatus)
      .then((res) => {
        contractData(userId);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function handleDelete(contractId) {
    await axios
      .post(`api/contract/delete-contract/${contractId}`)
      .then((res) => {
        contractData(userId);
        handleDeleteClose();
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const create = (docDefinition) => {
    const pdfDocGenerator = pdfMake.createPdf(docDefinition);
    pdfDocGenerator.getBlob((blob) => {
      const url = URL.createObjectURL(blob);
      setUrl(url);
    });
  };

  async function showModal(contractId, show) {
    if (show) {
      setShow(false);
      return;
    }

    try {
      const response = await axios.get(`api/contract/get-document-file/${contractId}`);

      if (response.data.isSigned) {
        setUrl(response.data.preSignedUrl);
        setShow(true);
        return;
      }

      const content = response.data.htmlContent;

      // Sadece PDF Blob'ı al
      const pdfBlob = await convertHtmlToPdf(content);

      // Blob'dan URL oluştur - bu kısım görüntüleme mantığına ait
      const pdfUrl = URL.createObjectURL(pdfBlob);

      setUrl(pdfUrl);
      setShow(true);

    } catch (error) {
      console.error('Error in contract modal:', error);
    }
  }


  async function downloadContract(contractId) {
    let docDefinition;

    await axios
      .get(`api/contract/get-contract/${contractId}`)
      .then((res) => {
        let contractHtmlContent = res.data["contractHTMLContent"];
        let htmlToPdf = htmlToPdfmake(contractHtmlContent, {
          tableAutoSize: true,
        });

        docDefinition = {
          content: htmlToPdf,
          tableAutoSize: true,
        };

        const linkm = document.createElement("a");
        const pdfDocGenerator = pdfMake.createPdf(docDefinition);
        pdfDocGenerator.getBlob((blob) => {
          const url = URL.createObjectURL(blob, "application/pdf");
          linkm.href = url;
          linkm.target = "_blank";
          linkm.download = "test.pdf";
          document.body.appendChild(linkm);
          linkm.click();
          document.body.removeChild(linkm);
          window.URL.revokeObjectURL(linkm);
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function handleTextareaChange(e) {
    setRejectReason(e.target.value);
    //console.log("red sebebi : " +rejectReason);
  }

  async function handleCellClick(props) {
    setSelectedRow(props.row);

    if (
      props.row.status === 4 &&
      props.row.rejectReason &&
      props.rejectReason !== "" &&
      props.field === "status"
    )
      handleDialogShow(props.row.rejectReason);
  }

  useEffect(() => {
    if (searchTerm && searchTerm.length >= 3) {
      const filtered = data.filter((row) =>
        row.contractorName.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredData(filtered);
    } else {
      setFilteredData(data);
    }
  }, [searchTerm, data]);

  const actionColumn = [
    {
      field: "action",
      headerName: "Aksiyon",
      width: 350,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            {/* <button onClick={() => downloadContract(params.row.contractId)} className="downloadButton">İndir</button>
                        <button onHide={() => setShow(false)} onClick={() => showModal(params.row.contractType, params.row.contractId, show)} className="viewButton">Görüntüle
                            
                        </button> */}
            <Modal show={approveShow} onHide={() => setApproveShow(false)}>
              <Modal.Body>Sözleşme Onaylanacak emin misiniz?</Modal.Body>
              <Modal.Footer>
                <Button
                  variant="secondary"
                  onClick={() => setApproveShow(false)}
                >
                  Hayır
                </Button>
                <Button
                  variant="primary"
                  onClick={(e) => handleApprove()}
                >
                  Evet
                </Button>
              </Modal.Footer>
            </Modal>
            <Modal show={rejectShow} onHide={() => setRejectShow(false)}>
              <Modal.Body>
                <TextField
                  id="outlined-textarea"
                  label="Red Nedeni"
                  placeholder="Lütfen Red Nedeninizi bu kısma metin olarak ekleyiniz. "
                  multiline
                  inputProps={{
                    style: {
                      height: "150px",
                    },
                  }}
                  fullWidth
                  value={rejectReason}
                  onChange={(e) => handleTextareaChange(e)}
                />
                <p>Sözleşme Reddolacak emin misiniz?</p>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="secondary"
                  onClick={() => setRejectShow(false)}
                >
                  Hayır
                </Button>
                <Button
                  variant="primary"
                  onClick={(e) => handleReject()}
                >
                  Evet
                </Button>
              </Modal.Footer>
            </Modal>
            <Modal show={show} onHide={() => setShow(false)}>
              <div style={{ position: "fixed" }}>
                {url && (
                  <object
                    style={{
                      width: "200%",
                      height: "75vh",
                    }}
                    data={url}
                    type="application/pdf"
                  >
                    <embed
                      frameborder="0"
                      width="300%"
                      height="500px"
                      src={url}
                      type="application/pdf"
                    />
                  </object>
                )}
              </div>
            </Modal>
            {/* should be open toast for archieve button and contract push to archieve repository */}
            {/* <button onHide={handleArchiveClose} onClick={() => setArchiveShow(true)} className="archieveButton">Arşivle</button> */}
            <Modal show={archiveShow} onHide={handleArchiveClose}>
              <Modal.Body>Sözleşme Arşivlensin mi?</Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleArchiveClose}>
                  Hayır
                </Button>
                <Button
                  variant="primary"
                  onClick={(e) => handleArchive(params.row.contractId)}
                >
                  Evet
                </Button>
              </Modal.Footer>
            </Modal>
            {/* <button onHide={handleDeleteClose} onClick={() => setDeleteShow(true)} className="deleteButton">Sil</button> */}
            <Modal show={deleteShow} onHide={handleDeleteClose}>
              <Modal.Body>Sözleşme Silinsin mi?</Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleDeleteClose}>
                  Hayır
                </Button>
                <Button
                  variant="primary"
                  onClick={(e) => handleDelete(params.row.contractId)}
                >
                  Evet
                </Button>
              </Modal.Footer>
            </Modal>
            <div>
            {params.row.direction === "incoming" ? (
                <Tooltip title="Gelen">
                  <IconButton>
                    <ArrowCircleDownIcon color="primary" />
                  </IconButton>
                </Tooltip>
              ) : 
              <Tooltip title="Giden">
                  <IconButton>
                    <ArrowCircleUpIcon color="primary" />
                  </IconButton>
                </Tooltip>}
              <Tooltip title="Görüntüle" onHide={() => setShow(false)}>
                <IconButton>
                  <SlideshowIcon
                    color="success"
                    onClick={() => showModal(params.row.contractId, show)}
                    onHide={() => setShow(false)}
                  />
                </IconButton>
              </Tooltip>
              {params.row.status === 1 ? (
                <Tooltip title="Onaya Gönder">
                  <IconButton onClick={() => handleSendContract(params.row.contractId)}>
                    <SendIcon color="primary" />
                  </IconButton>
                </Tooltip>
              ) : null}
              <Tooltip title="İmzala">
                <IconButton onClick={() => setSignedRow(params.row)}>
                  <BrushIcon
                    id="sign-positioned-button"
                    aria-controls={open ? "sign-positioned-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    onClick={handleClick}
                  />
                </IconButton>
              </Tooltip>
              <Menu
                id="sign-positioned-menu"
                aria-labelledby="sign-positioned-button"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
              >
                <MenuItem onClick={() => sign()}>Dijisöz İmza</MenuItem>
                <MenuItem onClick={() => signE()}>e-İmza</MenuItem>
                <MenuItem onClick={handleClose}>Mobil İmza</MenuItem>
              </Menu>
              {params.row.status === DocumentStatus.SentApprove && params.row.direction === "incoming" ?
            <Tooltip title="Onayla">
              <IconButton onClick={() => setApproveShow(true)}>
                <CheckCircleIcon color="primary" />
              </IconButton>
            </Tooltip> : null }
            {params.row.status === DocumentStatus.SentApprove && params.row.direction === "incoming" ?
            <Tooltip title="Reddet">
              <IconButton onClick={() => setRejectShow(true)}>
                <CancelIcon color="error" />
              </IconButton>
            </Tooltip> : null }
              <Tooltip title="Arşivle">
                <IconButton onClick={() => setArchiveShow(true)}>
                  <ArchiveIcon color="info" />
                </IconButton>
              </Tooltip>
              <Tooltip title="Sil">
                <IconButton onClick={() => setDeleteShow(true)}>
                  <DeleteIcon color="inherit" />
                </IconButton>
              </Tooltip>
              {/* <Select
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper-label"
                label="Sözleşme İşlemleri"
                margin="normal"
                sx={{ minWidth: 200 }}
                //onHide={() => setShow(false)}
                //onChange={(e) => handleSelectChange(params.row.contractId, e)}
                MenuProps={MenuProps}
              > */}
              {/* <MenuItem
                  onClick={() => downloadContract(params.row.contractId)}
                  sx={{ minWidth: 200 }}
                  value={"0"}
                >
                  İndir
                </MenuItem> */}
              {/* <MenuItem
                  onHide={() => setShow(false)}
                  onClick={() =>
                    showModal(
                      params.row.contractType,
                      params.row.contractId,
                      show
                    )
                  }
                  sx={{ minWidth: 200 }}
                  value={"1"}
                >
                  Görüntüle
                </MenuItem> */}
              {/* {params.row.status === 1 ? (
                  <MenuItem
                    onClick={() => handleSendContract(params.row.contractId)}
                    sx={{ minWidth: 200 }}
                    value={"2"}
                  >
                    Onaya Gönder
                  </MenuItem>
                ) : null} */}
              {/* <MenuItem
                  onHide={handleArchiveClose}
                  onClick={() => setArchiveShow(true)}
                  sx={{ minWidth: 200 }}
                  value={"3"}
                >
                  Arşivle
                </MenuItem> */}
              {/* <MenuItem
                  onHide={handleDeleteClose}
                  onClick={() => setDeleteShow(true)}
                  sx={{ minWidth: 200 }}
                  value={"4"}
                >
                  Sil
                </MenuItem> */}
              {/* </Select> */}
            </div>
          </div>
        );
      },
    },
  ];
  return (
    <div className="datatable">
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        closeOnClick={true}
        pauseOnHover={true}
        draggable={true}
        progress={undefined}
      />
      <Dialog
        open={openDialog}
        onClose={handleDialogClose}
        scroll="paper"
        fullWidth
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">Red Nedeni</DialogTitle>
        <DialogContent dividers="true">
          <DialogContentText>{dialogText}</DialogContentText>
        </DialogContent>
      </Dialog>
      <div className="datatableTitle">
        Dokümanlar
        {/* <Link to="/users/new" className="link">
                    Add New
                </Link> */}
      </div>
      <DataGrid
        className="datagrid"
        rows={filteredData.length > 0 ? filteredData : data}
        getRowId={(row) => row.contractId}
        columns={userColumns.concat(actionColumn)}
        localeText={trTR.components.MuiDataGrid.defaultProps.localeText}
        components={{ NoRowsOverlay }}
        pageSize={8}
        rowsPerPageOptions={[9]}
        onCellClick={handleCellClick}
      //checkboxSelection
      />
    </div>
  );
};

export default Datatable;
