import { TextField } from "@material-ui/core";
import ArchiveIcon from "@mui/icons-material/Archive";
import Autorenew from "@mui/icons-material/Autorenew";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import SendIcon from "@mui/icons-material/Send";
import SlideshowIcon from "@mui/icons-material/Slideshow";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import { DataGrid, trTR } from "@mui/x-data-grid";
import axios from "axios";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { DocumentCreationType, DocumentStatus, DocumentTypes } from "../../actions/types";
import { convertHtmlToPdf } from "../../util/pdf/PdfConverter";
import "../Layout/datatable.scss";
import { userColumns } from "../Layout/datatablesource";
import PageHeader from "../Layout/PageHeader";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

function NoRowsOverlay() {
  return (
    <Stack height="100%" alignItems="center" justifyContent="center">
      Sözleşme Kaydı Bulunmamaktadır
    </Stack>
  );
}

const ContractArchive = (props) => {
  const [data, setData] = useState([]);
  const user = useSelector((state) => state.security.user);
  const userId = user.id;
  const [url, setUrl] = useState(null);
  const [show, setShow] = useState(false);
  const [sendApproveShow, setSendApproveShow] = useState(false);
  const [approveShow, setApproveShow] = useState(false);
  const [rejectReason, setRejectReason] = useState(null);
  const [rejectShow, setRejectShow] = useState(false);
  const [deleteShow, setDeleteShow] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogText, setDialogText] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const identityNumber =
    user.company && user.company !== null
      ? user.company.identityNumber
      : user.identityNumber;

  async function contractData(userId) {
    await axios
      .get(
        `api/contract/get-all-archive-contract/${userId}/${DocumentTypes.Contract}`
      )
      .then((res) => {
        setData(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    userColumns.push({
      field: "isIncoming",
      headerName: "Taraf",
      width: 100,
      type: "text",
      valueGetter: ({ value, row }) => {
        if (row.isIncoming) return "Gelen";
        else return "Gönderilen";
      },
    });
    contractData(userId);
  }, [userId]);

  const create = (docDefinition) => {
    const pdfDocGenerator = pdfMake.createPdf(docDefinition);
    pdfDocGenerator.getBlob((blob) => {
      const url = URL.createObjectURL(blob);
      setUrl(url);
    });
  };

  async function showModal(contractId, show) {
    if (show) {
      setShow(false);
      return;
    }
  
    try {
      const response = await axios.get(`api/contract/get-document-file/${contractId}`);
      
      if (response.data.isSigned) {
        setUrl(response.data.preSignedUrl);
        setShow(true);
        return;
      }
  
      const content = response.data.htmlContent;
      
      // Sadece PDF Blob'ı al
      const pdfBlob = await convertHtmlToPdf(content);
      
      // Blob'dan URL oluştur - bu kısım görüntüleme mantığına ait
      const pdfUrl = URL.createObjectURL(pdfBlob);
      
      setUrl(pdfUrl);
      setShow(true);
  
    } catch (error) {
      console.error('Error in contract modal:', error);
    }
  }


  async function handleApprove() {
    let contractStatus = {
      id: selectedRow.contractId,
      status: DocumentStatus.Approve,
    };
    await axios
      .post(`api/contract-query-service/update-contract-status`, contractStatus)
      .then((res) => {
        contractData(userId);
        setApproveShow(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function handleReject() {
    let contractStatus = {
      id: selectedRow.contractId,
      status: DocumentStatus.Reject,
      rejectReason: rejectReason,
    };
    await axios
      .post(`api/contract-query-service/update-contract-status`, contractStatus)
      .then((res) => {
        contractData(userId);
        setRejectShow(false);
        setRejectReason("");
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function handleDelete() {
    let url = "delete-contract";
    if (selectedRow.isIncoming) url += "-receiver";
    await axios
      .post(`api/contract/${url}/${selectedRow.contractId}`)
      .then((res) => {
        contractData(userId);
        setDeleteShow(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function handleTextareaChange(e) {
    setRejectReason(e.target.value);
  }

  const handleDialogClose = () => {
    setOpenDialog(false);
    setDialogText(null);
  };

  const handleDialogShow = (rejectReason) => {
    setDialogText(rejectReason);
    setOpenDialog(true);
  };

  async function handleCellClick(props) {
    setSelectedRow(props.row);
    if (
      props.row.status === 4 &&
      props.row.rejectReason &&
      props.rejectReason !== "" &&
      props.field === "status"
    )
      handleDialogShow(props.row.rejectReason);
  }

  async function handleSendContract() {
    let contractStatus = {
      id: selectedRow.contractId,
      status: DocumentStatus.SentApprove,
    };
    await axios
      .post(`api/contract/update-contract-status`, contractStatus)
      .then((res) => {
        contractData(userId);
      })
      .catch((error) => {
        console.log(error);
      });

    setSendApproveShow(false);
  }

  async function getSignedDocumentUrl(name) {
    await axios
      .get(
        `api/sign/get-signed-document-url/${identityNumber}/${name + ".pdf"}`
      )
      .then((res) => {
        setUrl(res.data);
        setShow(true);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const actionColumn = [
    {
      field: "action",
      headerName: "",
      width: 280,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <Tooltip title="Görüntüle" onHide={() => setShow(false)}>
              <IconButton>
                <SlideshowIcon
                  color="success"
                  onClick={() => showModal(params.row.contractId, show)}
                  onHide={() => setShow(false)}
                />
              </IconButton>
            </Tooltip>
            <Modal show={show} onHide={() => setShow(false)}>
              <div onClick={() => setShow(false)}></div>
              <div style={{ position: "fixed" }}>
                {url && (
                  <object
                    style={{
                      width: "200%",
                      height: "75vh",
                    }}
                    data={url}
                    type="application/pdf"
                  >
                    <embed
                      frameborder="0"
                      width="300%"
                      height="500px"
                      src={url}
                      type="application/pdf"
                    />
                  </object>
                )}
              </div>
            </Modal>
            <Modal
              show={sendApproveShow}
              onHide={() => setSendApproveShow(false)}
            >
              <Modal.Body>Sözleşme Onaya Gönderilecek emin misiniz?</Modal.Body>
              <Modal.Footer>
                <Button
                  variant="secondary"
                  onClick={() => setSendApproveShow(false)}
                >
                  Hayır
                </Button>
                <Button variant="primary" onClick={(e) => handleSendContract()}>
                  Evet
                </Button>
              </Modal.Footer>
            </Modal>
            <Modal show={approveShow} onHide={() => setApproveShow(false)}>
              <Modal.Body>Sözleşme Onaylanacak emin misiniz?</Modal.Body>
              <Modal.Footer>
                <Button
                  variant="secondary"
                  onClick={() => setApproveShow(false)}
                >
                  Hayır
                </Button>
                <Button variant="primary" onClick={(e) => handleApprove()}>
                  Evet
                </Button>
              </Modal.Footer>
            </Modal>
            <Modal show={rejectShow} onHide={() => setRejectShow(false)}>
              <Modal.Body>
                <TextField
                  id="outlined-textarea"
                  label="Red Nedeni"
                  placeholder="Lütfen Red Nedeninizi bu kısma metin olarak ekleyiniz. "
                  multiline
                  inputProps={{
                    style: {
                      height: "150px",
                    },
                  }}
                  fullWidth
                  value={rejectReason}
                  onChange={(e) => handleTextareaChange(e)}
                />
                <p>Sözleşme Reddolacak emin misiniz?</p>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="secondary"
                  onClick={() => setRejectShow(false)}
                >
                  Hayır
                </Button>
                <Button variant="primary" onClick={(e) => handleReject()}>
                  Evet
                </Button>
              </Modal.Footer>
            </Modal>
            <Modal show={deleteShow} onHide={() => setDeleteShow(false)}>
              <Modal.Body>Sözleşme Silinsin mi?</Modal.Body>
              <Modal.Footer>
                <Button
                  variant="secondary"
                  onClick={() => setDeleteShow(false)}
                >
                  Hayır
                </Button>
                <Button variant="primary" onClick={(e) => handleDelete()}>
                  Evet
                </Button>
              </Modal.Footer>
            </Modal>
            {!params.row.isIncoming &&
            params.row.status === DocumentStatus.Draft ? (
              <Tooltip title="Onaya Gönder">
                <IconButton onClick={() => setSendApproveShow(true)}>
                  <SendIcon color="primary" />
                </IconButton>
              </Tooltip>
            ) : null}
            {params.row.isIncoming &&
            params.row.status === DocumentStatus.SentApprove ? (
              <Tooltip title="Onayla">
                <IconButton onClick={() => setApproveShow(true)}>
                  <CheckCircleIcon color="primary" />
                </IconButton>
              </Tooltip>
            ) : null}
            {params.row.isIncoming &&
            params.row.status === DocumentStatus.SentApprove ? (
              <Tooltip title="Reddet">
                <IconButton onClick={() => setRejectShow(true)}>
                  <CancelIcon color="error" />
                </IconButton>
              </Tooltip>
            ) : null}
            {!params.row.isIncoming ? (
              <Tooltip title="Doküman Yenile">
                <IconButton>
                  <Link
                    to={{
                      pathname: "/entryBasicContract",
                      state: {
                        documentCreationType: DocumentCreationType.Renewal,
                        id: params.row.contractId,
                      },
                    }}
                    style={{ color: "gray" }}
                  >
                    <Autorenew
                      style={{ color: "orange", variant: "fullwidth" }}
                    />
                  </Link>
                </IconButton>
              </Tooltip>
            ) : null}
            <Tooltip title="Sil">
              <IconButton onClick={() => setDeleteShow(true)}>
                <DeleteIcon color="inherit" />
              </IconButton>
            </Tooltip>
          </div>
        );
      },
    },
  ];
  return (
    <>
      <PageHeader
        title="Arşivlenmiş Sözleşmeler"
        subTitle="Arşivlenmiş Sözleşmeleri görüntüleyebilirsiniz."
        icon={<ArchiveIcon fontSize="large" color="action" />}
      />
      <div className="home">
        <div className="homeContainer">
          <div className="listContainer">
            <div className="datatable">
              <Dialog
                open={openDialog}
                onClose={handleDialogClose}
                scroll="paper"
                fullWidth
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
              >
                <DialogTitle id="scroll-dialog-title">Red Nedeni</DialogTitle>
                <DialogContent dividers="true">
                  <DialogContentText>{dialogText}</DialogContentText>
                </DialogContent>
              </Dialog>
              <div className="datatableTitle">Sözleşmeler</div>
              <DataGrid
                className="datagrid"
                rows={data}
                getRowId={(row) => row.contractId}
                columns={userColumns.concat(actionColumn)}
                localeText={trTR.components.MuiDataGrid.defaultProps.localeText}
                components={{ NoRowsOverlay }}
                pageSize={8}
                onCellClick={handleCellClick}
                rowsPerPageOptions={[10]}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContractArchive;
