import ArchiveIcon from "@mui/icons-material/Archive";
import DeleteIcon from "@mui/icons-material/Delete";
import SendIcon from "@mui/icons-material/Send";
import SlideshowIcon from "@mui/icons-material/Slideshow";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import { DataGrid, trTR } from "@mui/x-data-grid";
import axios from "axios";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useSelector } from "react-redux";
import { DocumentStatus, DocumentTypes } from "../../actions/types";
import { convertHtmlToPdf } from "../../util/pdf/PdfConverter";
import "../Layout/datatable.scss";
import { userColumns } from "../Layout/datatablesource";
import PageHeader from "../Layout/PageHeader";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

function NoRowsOverlay() {
  return (
    <Stack height="100%" alignItems="center" justifyContent="center">
      Fatura Kaydı Bulunmamaktadır
    </Stack>
  );
}

const InvoiceDraft = () => {
  const [data, setData] = useState([]);
  const user = useSelector((state) => state.security.user);
  const userId = user.id;
  const [url, setUrl] = useState(null);
  const [show, setShow] = useState(false);
  const [archiveShow, setArchiveShow] = useState(false);
  const [deleteShow, setDeleteShow] = useState(false);
  const [approveShow, setApproveShow] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  async function contractData(userId) {
    let request = {
      userId: userId,
      documentType: DocumentTypes.Invoice,
      status: DocumentStatus.Draft,
    };

    await axios
      .post(`api/contract/get-outgoing-contracts`, request)
      .then((res) => {
        setData(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    contractData(userId);
  }, [userId]);

  async function handleDelete() {
    await axios
      .post(`api/contract/delete-contract/${selectedRow.contractId}`)
      .then((res) => {
        contractData(userId);
        setDeleteShow(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function handleArchive() {
    await axios
      .post(`api/contract/move-contract-archive/${selectedRow.contractId}`)
      .then((res) => {
        contractData(userId);
        setArchiveShow(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function handleSendContract() {
    let contractStatus = {
      id: selectedRow.contractId,
      status: DocumentStatus.SentApprove,
    };
    await axios
      .post(`api/contract/update-contract-status`, contractStatus)
      .then((res) => {
        contractData(userId);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const create = (docDefinition) => {
    const pdfDocGenerator = pdfMake.createPdf(docDefinition);
    pdfDocGenerator.getBlob((blob) => {
      const url = URL.createObjectURL(blob);
      setUrl(url);
    });
  };

  async function showModal(contractId, show) {
    if (show) {
      setShow(false);
      return;
    }
  
    try {
      const response = await axios.get(`api/contract/get-document-file/${contractId}`);
      
      if (response.data.isSigned) {
        setUrl(response.data.preSignedUrl);
        setShow(true);
        return;
      }
  
      const content = response.data.htmlContent;
      
      // Sadece PDF Blob'ı al
      const pdfBlob = await convertHtmlToPdf(content);
      
      // Blob'dan URL oluştur - bu kısım görüntüleme mantığına ait
      const pdfUrl = URL.createObjectURL(pdfBlob);
      
      setUrl(pdfUrl);
      setShow(true);
  
    } catch (error) {
      console.error('Error in contract modal:', error);
    }
  }

  async function handleCellClick(e) {
    setSelectedRow(e.row);
  }
  const actionColumn = [
    {
      field: "action",
      headerName: "",
      width: 230,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <Modal show={show} onHide={() => setShow(false)}>
              <div onClick={() => setShow(false)}></div>
              <div style={{ position: "fixed" }}>
                {url && (
                  <object
                    style={{
                      width: "200%",
                      height: "75vh",
                    }}
                    data={url}
                    type="application/pdf"
                  >
                    <embed
                      frameborder="0"
                      width="300%"
                      height="500px"
                      src={url}
                      type="application/pdf"
                    />
                  </object>
                )}
              </div>
            </Modal>
            <Modal show={approveShow} onHide={() => setApproveShow(false)}>
              <Modal.Body>Fatura Onaya Gönderilecek emin misiniz?</Modal.Body>
              <Modal.Footer>
                <Button
                  variant="secondary"
                  onClick={() => setApproveShow(false)}
                >
                  Hayır
                </Button>
                <Button variant="primary" onClick={(e) => handleSendContract()}>
                  Evet
                </Button>
              </Modal.Footer>
            </Modal>
            <Modal show={archiveShow} onHide={() => setArchiveShow(false)}>
              <Modal.Body>Fatura Arşivlensin mi?</Modal.Body>
              <Modal.Footer>
                <Button
                  variant="secondary"
                  onClick={() => setArchiveShow(false)}
                >
                  Hayır
                </Button>
                <Button variant="primary" onClick={(e) => handleArchive()}>
                  Evet
                </Button>
              </Modal.Footer>
            </Modal>
            <Modal show={deleteShow} onHide={() => setDeleteShow(false)}>
              <Modal.Body>Fatura Silinsin mi?</Modal.Body>
              <Modal.Footer>
                <Button
                  variant="secondary"
                  onClick={() => setDeleteShow(false)}
                >
                  Hayır
                </Button>
                <Button variant="primary" onClick={(e) => handleDelete()}>
                  Evet
                </Button>
              </Modal.Footer>
            </Modal>
            <Tooltip title="Görüntüle" onHide={() => setShow(false)}>
              <IconButton>
                <SlideshowIcon
                  color="success"
                  onClick={() =>
                    showModal(
                      params.row.contractType,
                      params.row.contractId,
                      show
                    )
                  }
                  onHide={() => setShow(false)}
                />
              </IconButton>
            </Tooltip>
            <Tooltip title="Onaya Gönder">
              <IconButton onClick={() => setApproveShow(true)}>
                <SendIcon color="primary" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Arşivle">
              <IconButton onClick={() => setArchiveShow(true)}>
                <ArchiveIcon color="info" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Sil">
              <IconButton onClick={() => setDeleteShow(true)}>
                <DeleteIcon color="inherit" />
              </IconButton>
            </Tooltip>
          </div>
        );
      },
    },
  ];
  return (
    <>
      <PageHeader
        title="Taslak Faturalar"
        subTitle="Taslak Faturaları görüntüleyebilirsiniz."
        icon={<ArchiveIcon fontSize="large" color="action" />}
      />
      <div className="home">
        <div className="homeContainer">
          <div className="listContainer">
            <div className="datatable">
              <div className="datatableTitle">Faturalar</div>
              <DataGrid
                className="datagrid"
                rows={data}
                getRowId={(row) => row.contractId}
                columns={userColumns.concat(actionColumn)}
                localeText={trTR.components.MuiDataGrid.defaultProps.localeText}
                components={{ NoRowsOverlay }}
                pageSize={8}
                onCellClick={handleCellClick}
                rowsPerPageOptions={[10]}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default InvoiceDraft;
