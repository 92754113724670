import { React, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import "./home.scss";
import ActionButtons from "./Layout/ActionButtons";
import Datatable from "./Layout/DataTable";
import "./Layout/list.scss";
import "./Layout/Sidebar.css";
import Widget from "./Layout/Widget";

const Dashboard = () => {
  const history = useHistory();
  const contracts = useSelector((state) => state.contract.contracts);
  const numberOfContract = contracts.data?.length || 0;
  const [searchTerm, setSearchTerm] = useState('');

  const handleSearch = (term) => {
    setSearchTerm(term);
  };

  return (
    <div>
       <ActionButtons onSearch={handleSearch}/>
    <div className="home">
      {/* <SideBar /> */}
      <div className="homeContainer">
      
        {/* <table>
          <tr>
            <td>
              <img src="../images/aylaragoresozlesme.png" alt="Home" />
            </td>
            <td>
              <img src="../images/sozlesmestatu.png" alt="Home" />
            </td>
          </tr>
          <tr>
            <td>
              <img src="../images/sozlesmetutar.png" alt="Home" />
            </td>
            <td>
              <img src="../images/personel_contract.jpg" alt="Home" />
            </td>
          </tr>
        </table> */}
        <div className="widgets">
          <Widget type="user" amount={numberOfContract} />
          {/* <Widget type="balance" amount="1500" /> */}
        </div>
        <div className="listContainer">
          <Datatable searchTerm={searchTerm}/>
        </div>
      </div>
    </div>
    </div>
  );
};

export default Dashboard;
