import React from "react";
import "../Layout/list.scss";
import "../home.scss";
import ReceiveDataTable from "../Layout/ReceiveDataTable";

const UsersViewAllContract = (props) => {
  const phoneNumber = props.contractInformations["phoneNumber"];
  const identifierNumber = props.contractInformations["identifierNumber"];

  return (
    <div className="home">
      <div className="homeContainer">
        <div className="listContainer">
          <ReceiveDataTable
            phoneNumber={phoneNumber}
            identifierNumber={identifierNumber}
          />
        </div>
      </div>
    </div>
  );
};

export default UsersViewAllContract;
