import { Archive, Clock, FileText, PlusCircle, Search, Send, X } from 'lucide-react';
import { React, useState } from 'react';
import { Link } from 'react-router-dom'; // Link bileşenini import ediyoruz
import { DocumentTypes } from '../../actions/types';

const ActionButtons = ({ onSearch }) => {
  const [showSearch, setShowSearch] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  const buttons = [
    {
      icon: <PlusCircle size={20} />,
      label: 'Doküman Oluştur',
      path: '/entryBasicContract'
    },
    {
      icon: <Clock size={20} />,
      label: 'Onayımda Bekleyenler',
      path: '/incomingDocuments',
      documentType: DocumentTypes.All
    },
    {
      icon: <Send size={20} />,
      label: 'Onaya Gönderdiklerim',
      path: '/outgoingDocuments',
      documentType: DocumentTypes.All
    },
    {
      icon: <FileText size={20} />,
      label: 'Taslak Dokümanlar',
      path: '/draftDocuments',
      documentType: DocumentTypes.All
    },
    {
      icon: <Archive size={20} />,
      label: 'Arşivlenenler',
      path: '/archivedDocuments',
      documentType: DocumentTypes.All
    }
  ];

  const handleSearchClick = () => {
    setShowSearch(!showSearch);
    if (!showSearch) {
      setSearchTerm('');
      onSearch(''); // Reset search when opening
    }
  };

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    if (value.length >= 3) {
      onSearch(value);
    } else if (value.length === 0) {
      onSearch(''); // Reset search when clearing
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        gap: '1rem',
        padding: '1.5rem',
        backgroundColor: 'white',
        borderRadius: '0.5rem',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
        marginBottom: '1.5rem'
      }}
    >
      {buttons.map((btn) => (
        <div
          key={btn.label}
          style={{
            position: 'relative',
            display: 'inline-block'
          }}
        >
          <Link
            to={{
              pathname: btn.path,
              state: { documentType: btn.documentType }
            }}
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '0.5rem',
              padding: '0.5rem 1rem',
              backgroundColor: '#2B4B80',
              color: 'white',
              borderRadius: '0.5rem',
              border: 'none',
              cursor: 'pointer',
              textDecoration: 'none',
              transition: 'background-color 0.3s',
            }}
          >
            {btn.icon}
            <span style={{ fontSize: '0.875rem' }}>{btn.label}</span>
          </Link>
        </div>
      ))}
      
      <div className="relative">
        <button
          onClick={handleSearchClick}
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '0.5rem',
            padding: '0.5rem 1rem',
            backgroundColor: '#2B4B80',
            color: 'white',
            borderRadius: '0.5rem',
            border: 'none',
            cursor: 'pointer',
            transition: 'background-color 0.3s',
          }}
        >
          <Search size={20} />
          <span style={{ fontSize: '0.875rem' }}>Müşteriye Göre Ara</span>
        </button>
        
        {showSearch && (
          <div className="absolute mt-2 right-0 flex items-center">
            <input
              type="text"
              value={searchTerm}
              onChange={handleSearchChange}
              placeholder="Müşteri ara..."
              className="p-2 border rounded-md w-64"
              autoFocus
            />
            <button
              onClick={() => {
                setSearchTerm('');
                onSearch('');
                setShowSearch(false);
              }}
              className="ml-2 p-2 rounded-md bg-gray-200 hover:bg-gray-300"
            >
              <X size={20} />
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default ActionButtons;