import { Box, Button, Card, CardContent, Divider, Grid, IconButton, MenuItem, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography } from '@mui/material';
import { Pencil, Plus, Trash2 } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { CostItemUnit } from '../../constant/CostItemUnit';

interface CostItem {
    id: number | null;
    productName: string;
    quantity: number;
    unit: string;
    unitPrice: number;
    vatRate: number;
    discountRate: number;
    total?: number;
}

interface CostItemTotal {
    id: number | null;
    subTotal: number;
    totalDiscount: number;
    totalVat: number;
    grandTotal: number;
    offer: number;
}

interface CostItemsProps {
    initialData?: CostItem[];
    costItemTotal?: CostItemTotal;
    onChange?: (items: CostItem[], itemTotal: CostItemTotal) => void;
}

const calculateItemTotal = (item: Partial<CostItem>) => {
    if (!item.quantity || !item.unitPrice) return 0;

    const baseAmount = item.quantity * item.unitPrice;
    const afterDiscount = baseAmount * ((100 - (item.discountRate || 0)) / 100);
    return afterDiscount * ((100 + (item.vatRate || 0)) / 100);
};

const calculateSubTotal = (items: CostItem[]) => {
    return items.reduce((sum, item) => sum + (item.quantity * item.unitPrice), 0);
};

const calculateTotalDiscount = (items: CostItem[]) => {
    return items.reduce((sum, item) => {
        const baseAmount = item.quantity * item.unitPrice;
        return sum + (baseAmount * (item.discountRate || 0) / 100);
    }, 0);
};

const calculateTotalVat = (items: CostItem[]) => {
    return items.reduce((sum, item) => {
        const baseAmount = item.quantity * item.unitPrice;
        const afterDiscount = baseAmount * ((100 - (item.discountRate || 0)) / 100);
        return sum + (afterDiscount * (item.vatRate || 0) / 100);
    }, 0);
};

const CostItems: React.FC<CostItemsProps> = ({ initialData = [], costItemTotal, onChange }) => {
    const [items, setItems] = useState<CostItem[]>(initialData);
    const [itemTotal, setItemTotal] = useState<CostItemTotal>(costItemTotal || {
        id: null,
        subTotal: 0,
        totalDiscount: 0,
        totalVat: 0,
        grandTotal: 0,
        offer: 0
    });
    const [currentItem, setCurrentItem] = React.useState<Partial<CostItem>>({
        productName: '',
        quantity: undefined,
        unit: '',
        unitPrice: undefined,
        vatRate: undefined,
        discountRate: undefined
    });
    const [currentItemIndex, setCurrentItemIndex] = React.useState<number | null>(null);

    const {
        register,
        formState: { errors },
        setValue,
        getValues,
        watch,
    } = useFormContext();

    const paymentTypeValue = watch('paymentType');
    // Toplam değerlerin ana forma set edilmesi için effect
    useEffect(() => {
        const subTotal = calculateSubTotal(items);
        const totalDiscount = calculateTotalDiscount(items);
        const totalVat = calculateTotalVat(items);
        const grandTotal = subTotal - totalDiscount + totalVat;

        const newItemTotal = {
            ...itemTotal,
            subTotal,
            totalDiscount,
            totalVat,
            grandTotal,
            offer: getValues('contractFee') || 0
        };

        setItemTotal(newItemTotal);
        
        // Only call onChange when we have actual changes
        if (onChange) {
            onChange(items, newItemTotal);
        }
    }, [items, getValues('contractFee'), setValue]);

    const handleAddItem = () => {
        const newItem: CostItem = {
            productName: currentItem.productName || '',
            quantity: currentItem.quantity || 0,
            unit: currentItem.unit || '', // Direkt currentItem'dan al
            unitPrice: currentItem.unitPrice || 0,
            vatRate: currentItem.vatRate || 0,
            discountRate: currentItem.discountRate || 0,
            total: calculateItemTotal(currentItem),
            id: currentItem.id || null
        };

        if (currentItemIndex !== null) {
            setItems(prevItems => {
                const updatedItems = [...prevItems];
                updatedItems[currentItemIndex] = newItem;
                return updatedItems;
            });
        } else {
            setItems(prevItems => [...prevItems, newItem]);
        }

        setCurrentItem({
            productName: '',
            quantity: undefined,
            unit: '',
            unitPrice: undefined,
            vatRate: undefined,
            discountRate: undefined,
            id: null
        });
        setCurrentItemIndex(null);
        /* console.log("3");console.log(items)
        onChange?.(items); */
    };

    const handleEdit = (index: number) => {
        console.log("index : " + index)
        const itemToEdit = items[index];
        console.log(itemToEdit)
        setCurrentItem(itemToEdit);
        console.log(currentItem)
        setCurrentItemIndex(index);
    };

    const handleDelete = (index: number) => {
        const updatedItems = [...items];
        updatedItems.splice(index, 1);
        setItems(updatedItems);
        //onChange?.(updatedItems);
    };

    const validateForm = () => {
        if (!currentItem.productName || currentItem.productName.trim() === '') {
            return false;
        }
        if (!currentItem.quantity || currentItem.quantity <= 0 || currentItem.quantity > 1000) {
            return false;
        }
        if (!currentItem.unit) {
            return false;
        }
        if (!currentItem.unitPrice || currentItem.unitPrice <= 0 || currentItem.unitPrice > 100000) {
            return false;
        }
        if (currentItem.vatRate && (currentItem.vatRate < 0 || currentItem.vatRate > 200)) {
            return false;
        }
        if (currentItem.discountRate && (currentItem.discountRate < 0 || currentItem.discountRate > 99)) {
            return false;
        }
        return true;
    };

    const subTotal = calculateSubTotal(items);
    const totalDiscount = calculateTotalDiscount(items);
    const totalVat = calculateTotalVat(items);
    const grandTotal = subTotal - totalDiscount + totalVat;

    const handleContractFeeChange = (value: number) => {
        setValue('contractFee', value);
        setItemTotal(prev => ({
            ...prev,
            offer: value
        }));
    };

    return (
        <Card sx={{ mb: 1 }}>
            <CardContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            value={currentItem.productName || ''}
                            onChange={(e) => setCurrentItem({ ...currentItem, productName: e.target.value })}
                            label="Ürün/Hizmet Adı"
                            fullWidth
                            error={!currentItem.productName}
                            helperText={!currentItem.productName ? 'Ürün/Hizmet adı zorunludur' : ''}
                        />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <TextField
                            value={currentItem.quantity || ''}
                            onChange={(e) => setCurrentItem({ ...currentItem, quantity: parseFloat(e.target.value) })}
                            type="number"
                            label="Miktar"
                            fullWidth
                            error={!currentItem.quantity || currentItem.quantity <= 0 || currentItem.quantity > 1000}
                            helperText={!currentItem.quantity || currentItem.quantity <= 0 ? 'Miktar 0\'dan büyük olmalıdır' : currentItem.quantity > 1000 ? 'Miktar 1000\'den büyük olamaz' : ''}
                        />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <TextField
                            value={currentItem.unit || ''}
                            onChange={(e) => setCurrentItem({ ...currentItem, unit: e.target.value })}
                            label="Birim"
                            fullWidth
                            select
                            error={!currentItem.unit}
                            helperText={!currentItem.unit ? 'Birim seçimi zorunludur' : ''}
                        >
                            {CostItemUnit.map((unit) => (
                                <MenuItem key={unit.value} value={unit.value}>
                                    {unit.label}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <TextField
                            value={currentItem.unitPrice || ''}
                            onChange={(e) => setCurrentItem({ ...currentItem, unitPrice: parseFloat(e.target.value) })}
                            type="number"
                            label="Birim Fiyat"
                            fullWidth
                            error={!currentItem.unitPrice || currentItem.unitPrice <= 0 || currentItem.unitPrice > 100000}
                            helperText={!currentItem.unitPrice || currentItem.unitPrice <= 0 ? 'Birim fiyat 0\'dan büyük olmalıdır' : currentItem.unitPrice > 100000 ? 'Birim fiyat 100.000\'den büyük olamaz' : ''}
                        />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <TextField
                            value={currentItem.vatRate || ''}
                            onChange={(e) => setCurrentItem({ ...currentItem, vatRate: parseFloat(e.target.value) })}
                            type="number"
                            label="KDV %"
                            fullWidth
                            error={currentItem.vatRate !== undefined && (currentItem.vatRate < 0 || currentItem.vatRate > 200)}
                            helperText={currentItem.vatRate !== undefined && (currentItem.vatRate < 0 || currentItem.vatRate > 200) ? 'KDV oranı 0-200 arasında olmalıdır' : ''}
                        />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <TextField
                            value={currentItem.discountRate || ''}
                            onChange={(e) => setCurrentItem({ ...currentItem, discountRate: parseFloat(e.target.value) })}
                            type="number"
                            label="İndirim %"
                            fullWidth
                            error={currentItem.discountRate !== undefined && (currentItem.discountRate < 0 || currentItem.discountRate > 99)}
                            helperText={currentItem.discountRate !== undefined && (currentItem.discountRate < 0 || currentItem.discountRate > 99) ? 'İndirim oranı 0-99 arasında olmalıdır' : ''}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Box display="flex" justifyContent="space-between" alignItems="center">
                            <Typography variant="body1" fontWeight="bold">
                                Toplam: {calculateItemTotal(currentItem).toFixed(2)} TL
                            </Typography>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleAddItem}
                                startIcon={<Plus />}
                                disabled={!validateForm()}
                            >
                                {currentItemIndex !== null ? 'Güncelle' : 'Ekle'}
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </CardContent>

            <Divider />

            <CardContent>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Ürün/Hizmet</TableCell>
                            <TableCell align="right">Miktar</TableCell>
                            <TableCell align="right">Birim</TableCell>
                            <TableCell align="right">Birim Fiyat</TableCell>
                            <TableCell align="right">KDV %</TableCell>
                            <TableCell align="right">İndirim %</TableCell>
                            <TableCell align="right">Toplam</TableCell>
                            <TableCell align="center">İşlemler</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {items.map((item, index) => (
                            <TableRow key={index}>
                                <TableCell>{item.productName}</TableCell>
                                <TableCell align="right">{item.quantity}</TableCell>
                                <TableCell align="right">{item.unit}</TableCell>
                                <TableCell align="right">{item.unitPrice.toFixed(2)}</TableCell>
                                <TableCell align="right">{item.vatRate || 0}</TableCell>
                                <TableCell align="right">{item.discountRate || 0}</TableCell>
                                <TableCell align="right">{item.total?.toFixed(2)}</TableCell>
                                <TableCell align="center">
                                    <IconButton
                                        onClick={() => handleEdit(index)}
                                        
                                        color="primary"
                                        size="small"
                                    >
                                        <Pencil />
                                    </IconButton>
                                    <IconButton
                                        onClick={() => handleDelete(index)}
                                        color="error"
                                        size="small"
                                    >
                                        <Trash2 />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </CardContent>

            <Divider />

            <CardContent>
                <Box display="flex" justifyContent="flex-end">
                    <Table sx={{ maxWidth: '400px' }}>
                        <TableBody>
                            <TableRow>
                                <TableCell>Ara Toplam:</TableCell>
                                <TableCell align="right">{subTotal.toFixed(2)} TL</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Toplam İndirim:</TableCell>
                                <TableCell align="right">{totalDiscount.toFixed(2)} TL</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Toplam KDV:</TableCell>
                                <TableCell align="right">{totalVat.toFixed(2)} TL</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <Typography variant="subtitle1" fontWeight="bold">Genel Toplam:</Typography>
                                </TableCell>
                                <TableCell align="right">
                                    <Typography variant="subtitle1" fontWeight="bold">{grandTotal.toFixed(2)} TL</Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <Typography variant="subtitle1" fontWeight="bold">Teklif Tutarı:</Typography>
                                </TableCell>
                                <TableCell align="right">
                                    <TextField
                                        id='contractFee'
                                        type="number"
                                        label="Teklif Tutarı"
                                        fullWidth
                                        inputProps={{
                                            min: 0,
                                            max: 1000000000,
                                            step: "any"
                                        }}
                                        {...register("contractFee", {
                                            required: false,
                                            setValueAs: (value: string) => value === '' ? 0 : parseFloat(value),
                                            min: {
                                                value: 0,
                                                message: "Teklif tutarı 0'dan küçük olamaz"
                                            },
                                            max: {
                                                value: 1000000000,
                                                message: "Teklif tutarı 1 milyardan büyük olamaz"
                                            },

                                            validate: {
                                                positive: (value) => value >= 0 || "Teklif tutarı 0'dan küçük olamaz",
                                                lessThanBillion: (value) => value <= 1000000000 || "Teklif tutarı 1 milyardan büyük olamaz"
                                            }
                                        })}
                                    />
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Box>
            </CardContent>
            <Divider />
            <CardContent>
                <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: 3, width: '100%' }}>
                    <TextField
                        id="payment-date"
                        type="date"
                        label="Ödeme Vadesi"
                        variant="outlined"
                        fullWidth
                        InputLabelProps={{
                            shrink: true,
                        }}
                        margin="normal"
                        {...register("paymentDate", {
                            required: false,
                        })}
                    />
                    <TextField
                        id="payment-Type"
                        variant="outlined"
                        label="Ödeme Türü"
                        fullWidth
                        margin="normal"
                        select
                        value={paymentTypeValue || ''}
                        onChange={(e) => setValue('paymentType', e.target.value)}
                        //{...register("paymentType")}
                    >
                        <MenuItem sx={{ minWidth: 200 }} value={"Nakit"}>
                            Nakit
                        </MenuItem>
                        <MenuItem sx={{ minWidth: 200 }} value={"Kredi Kartı"}>
                            Kredi Kartı
                        </MenuItem>
                        <MenuItem sx={{ minWidth: 200 }} value={"Havale/EFT"}>
                            Havale / EFT
                        </MenuItem>
                        <MenuItem sx={{ minWidth: 200 }} value={"Çek"}>
                            Çek
                        </MenuItem>
                        <MenuItem sx={{ minWidth: 200 }} value={"Senet"}>
                            Senet
                        </MenuItem>
                    </TextField>
                </Box>
            </CardContent>
        </Card>
    );
};

export default CostItems;