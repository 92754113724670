import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/core/styles";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import MenuIcon from "@material-ui/icons/Menu";
import EditNote from "@mui/icons-material/Api";
import ArchiveIcon from "@mui/icons-material/Archive";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import EditNoteIcon from "@mui/icons-material/DesignServices";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import GradingOutlinedIcon from "@mui/icons-material/GradingOutlined";
import PeopleAlt from "@mui/icons-material/Group";
import OfferIcon from "@mui/icons-material/LocalOffer";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import OutboxIcon from "@mui/icons-material/Outbox";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import Collapse from "@mui/material/Collapse";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { logout } from "../../actions/securityActions";
import "./Sidebar.scss";

const useStyles = makeStyles({
  list: {
    width: 250,
  },
});

export default function Sidebarr() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [nestedOpen, setNestedOpen] = useState(false);
  const [nestedOffer, setNestedOffer] = useState(false);
  const [nestedInvoice, setNestedInvoice] = useState(false);

  function Logout() {
    dispatch(logout());
    window.location.href = "/";
  }

  const handleClick = () => {
    setNestedOpen(!nestedOpen);
  };

  const offerClick = () => {
    setNestedOffer(!nestedOffer);
  };

  const invoiceClick = () => {
    setNestedInvoice(!nestedInvoice);
  };

  return (
    <div>
      <IconButton
        edge="start"
        color="inherit"
        aria-label="open drawer"
        onClick={() => setOpen(true)}
      >
        <MenuIcon />
      </IconButton>
      <SwipeableDrawer
        anchor="left"
        open={open}
        onClose={() => setOpen(false)}
        onOpen={() => {}}
      >
        <div className={classes.list}>
          <Box textAlign="center" p={2} fontSize="35px">
          <img height={75} width={100} src="../images/dijisoz.png" alt="Home" />
           {/*  Diji Söz
            <span> </span>
            <i class="fas fa-leaf"></i> */}
          </Box>
          <Divider />
          <List>
            <ListItemButton onClick={handleClick}>
              <ListItemIcon>
                <GradingOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary="Sözleşmelerim" />
              {nestedOpen ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={nestedOpen} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <Link to="/draftContracts" style={{ color: "black" }}>
                  <List component="div" disablePadding>
                    <ListItemButton sx={{ pl: 4 }}>
                      <ListItemIcon>
                        <EditNote />
                      </ListItemIcon>
                      <ListItemText primary="Taslak" />
                    </ListItemButton>
                  </List>
                </Link>
                <Link to="/outgoingContracts" style={{ color: "black" }}>
                  <ListItemButton sx={{ pl: 4 }}>
                    <ListItemIcon>
                      <OutboxIcon />
                    </ListItemIcon>
                    {/* <ListItemText primary="Gönderilenler" onClick={() => {
                                        window.location.href = "/dashboard";
                                    }} /> */}
                    <ListItemText primary="Gönderilenler" />
                  </ListItemButton>
                </Link>

                <Link to="/incomingContracts" style={{ color: "black" }}>
                  <List component="div" disablePadding>
                    <ListItemButton sx={{ pl: 4 }}>
                      <ListItemIcon>
                        <InboxIcon />
                      </ListItemIcon>
                      <ListItemText primary="Gelenler" />
                    </ListItemButton>
                  </List>
                </Link>
                <Link to="/archiveContracts" style={{ color: "black" }}>
                  <List component="div" disablePadding>
                    <ListItemButton sx={{ pl: 4 }}>
                      <ListItemIcon>
                        <ArchiveIcon />
                      </ListItemIcon>
                      <ListItemText primary="Arşiv" />
                    </ListItemButton>
                  </List>
                </Link>
              </List>
            </Collapse>
            {/* <ListItemButton>
                            <ListItemIcon>
                                <DraftsIcon />
                            </ListItemIcon>
                            <ListItemText primary="Taslak Oluştur" onClick={() => {
                                window.location.href = "/createContractDrafts";
                            }} />
                        </ListItemButton> */}
            <ListItemButton onClick={offerClick}>
              <ListItemIcon>
                <OfferIcon />
              </ListItemIcon>
              <ListItemText primary="Tekliflerim" />
              {nestedOffer ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={nestedOffer} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <Link to="/draftOffers" style={{ color: "black" }}>
                  <List component="div" disablePadding>
                    <ListItemButton sx={{ pl: 4 }}>
                      <ListItemIcon>
                        <EditNote />
                      </ListItemIcon>
                      <ListItemText primary="Taslak" />
                    </ListItemButton>
                  </List>
                </Link>
                <Link to="/outgoingOffers" style={{ color: "black" }}>
                  <ListItemButton sx={{ pl: 4 }}>
                    <ListItemIcon>
                      <OutboxIcon />
                    </ListItemIcon>
                    {/* <ListItemText primary="Gönderilenler" onClick={() => {
                                        window.location.href = "/dashboard";
                                    }} /> */}
                    <ListItemText primary="Gönderilenler" />
                  </ListItemButton>
                </Link>

                <Link to="/incomingOffers" style={{ color: "black" }}>
                  <List component="div" disablePadding>
                    <ListItemButton sx={{ pl: 4 }}>
                      <ListItemIcon>
                        <InboxIcon />
                      </ListItemIcon>
                      <ListItemText primary="Gelenler" />
                    </ListItemButton>
                  </List>
                </Link>
                <Link to="/archiveOffers" style={{ color: "black" }}>
                  <List component="div" disablePadding>
                    <ListItemButton sx={{ pl: 4 }}>
                      <ListItemIcon>
                        <ArchiveIcon />
                      </ListItemIcon>
                      <ListItemText primary="Arşiv" />
                    </ListItemButton>
                  </List>
                </Link>
              </List>
            </Collapse>

            <ListItemButton onClick={invoiceClick}>
              <ListItemIcon>
                <DescriptionOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary="Faturalarım" />
              {nestedOffer ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={nestedInvoice} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <Link to="/draftInvoices" style={{ color: "black" }}>
                  <List component="div" disablePadding>
                    <ListItemButton sx={{ pl: 4 }}>
                      <ListItemIcon>
                        <EditNote />
                      </ListItemIcon>
                      <ListItemText primary="Taslak" />
                    </ListItemButton>
                  </List>
                </Link>
                <Link to="/outgoingInvoices" style={{ color: "black" }}>
                  <ListItemButton sx={{ pl: 4 }}>
                    <ListItemIcon>
                      <OutboxIcon />
                    </ListItemIcon>
                    {/* <ListItemText primary="Gönderilenler" onClick={() => {
                                        window.location.href = "/dashboard";
                                    }} /> */}
                    <ListItemText primary="Gönderilenler" />
                  </ListItemButton>
                </Link>

                <Link to="/incomingInvoices" style={{ color: "black" }}>
                  <List component="div" disablePadding>
                    <ListItemButton sx={{ pl: 4 }}>
                      <ListItemIcon>
                        <InboxIcon />
                      </ListItemIcon>
                      <ListItemText primary="Gelenler" />
                    </ListItemButton>
                  </List>
                </Link>
                <Link to="/archiveInvoices" style={{ color: "black" }}>
                  <List component="div" disablePadding>
                    <ListItemButton sx={{ pl: 4 }}>
                      <ListItemIcon>
                        <ArchiveIcon />
                      </ListItemIcon>
                      <ListItemText primary="Arşiv" />
                    </ListItemButton>
                  </List>
                </Link>
              </List>
            </Collapse>
            <Link to="/profile" style={{ color: "black" }}>
              <ListItemButton>
                <ListItemIcon>
                  <PersonOutlineOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary="Profil" />
              </ListItemButton>
            </Link>
            <Link to="/customers" style={{ color: "black" }}>
              <ListItemButton>
                <ListItemIcon>
                  <PeopleAlt />
                </ListItemIcon>
                <ListItemText primary="Müşteriler" />
              </ListItemButton>
            </Link>
            <Link to="/templates" style={{ color: "black" }}>
              <ListItemButton>
                <ListItemIcon>
                  <EditNoteIcon />
                </ListItemIcon>
                <ListItemText primary="Şablonlar" />
              </ListItemButton>
            </Link>
            <Link onClick={() => Logout()} style={{ color: "black" }}>
              <ListItemButton>
                <ListItemIcon>
                  <LogoutOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary="Çıkış" />
              </ListItemButton>
            </Link>
          </List>
        </div>
      </SwipeableDrawer>
    </div>
  );
}
