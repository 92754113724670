import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import Slide from '@material-ui/core/Slide';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import {
  Button, Menu,
  MenuItem, Tab, Tabs
} from '@mui/material';
import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import LanguageSwitcher from '../../localization/LanguageSwitcher';
import Sidebarr from '././Sidebarr';
import Account from './Account';


function HideOnScroll({ children }) {
  const trigger = useScrollTrigger();
  return (
    <Slide appear={false} direction={'down'} in={!trigger}>
      {children}
    </Slide>
  );
}

export default function Header() {

  const user = useSelector((state) => state.security.user);
  const validToken = useSelector(state => state.security.validToken);
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const [showIdentityMessage, setShowIdentityMessage] = useState(false);
  const routes = ["/services", "/about", "/contact", "/contractQuery", "/dashboard", "/adminDashboard", "/createContractDrafts", "/invoiceCards", "/offers"];

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleClick = (e) => {
    let isValid = (user.identityNumber != null && user.identityNumber != "");
    console.log(isValid)
    isValid = isValid || (user.company != null && user.company.identityNumber != null && user.company.identityNumber != "");  // Kontrol fonksiyonunuzu buraya yazın
    console.log(isValid)
    if (!isValid) {
      e.preventDefault();
      setShowIdentityMessage(true)
    }
  };
  const userIsNotAuthenticated = (

    <HideOnScroll>
      <AppBar position="static" color="inherit">
        <Toolbar sx={{ justifyContent: "space-between" }}>
          {/* <Sidebarr /> */}
          <Box sx={{ marginRight: "100px" }}>
            <Link to="/" style={{ color: 'black' }}>
              <img height={100} width={100} src="../images/dijisoz.png" alt="Home" />
            </Link>
          </Box>
          <Tabs sx={{ margin: "auto" }}
            textColor="inherit"
            variant="fullWidth"
          >
            <Tab
              label="Hizmetler"
              component={Link}
              to={routes[0]}
            />
            <Tab
              label="Hakkımızda"
              component={Link}
              to={routes[1]}
            />
            <Tab
              label="İletişim"
              component={Link}
              to={routes[2]}
            />
          </Tabs>

          <Link to="/login" style={{ color: 'black' }}>
            <Button color="" variant="">
              Giriş Yap
            </Button>
          </Link>

          <Link to="/contractQuery" style={{ color: 'black' }}>
            <Button color="" variant="" href='/contractQuery'>
              Sözleşme Sorgula
            </Button>
          </Link>
        </Toolbar>
      </AppBar>
    </HideOnScroll>


  )

  const userIsAuthenticated = (

    <HideOnScroll>
      <AppBar position="static" color="inherit">
        <Modal show={showIdentityMessage} onHide={() => setShowIdentityMessage(false)}>
          <Modal.Body>Belge oluşturma ve diğer işlemler için TCKN bilginizi eğer bir şirket adına işlem yapacaksanız
            şirket bilgilerinizi Profil Menusunden girebilirsiniz.
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => setShowIdentityMessage(false)}
            >
              Tamam
            </Button>

          </Modal.Footer>
        </Modal>
        <Toolbar>
          <Sidebarr />
          <Box>
            <img height={100} width={100} src="../images/dijisoz.png" alt="Home" />
            {/* <Typography variant="h6">Diji Söz
              <span>   </span>
              <i class="fas fa-leaf"></i>
            </Typography> */}
          </Box>
          {/* <Box sx={{ marginLeft: "100px" }}>
              
              <Link to="/entryBasicContract" onClick={handleClick} style={{ color: "gray" }}>
                <NoteAddIcon style={{ color: "purple", variant: "fullwidth" }}/>
                Belge Oluştur
              </Link>
            
          </Box> */}
          {/* <Tabs sx={{ marginLeft: "auto" }}
            indicatorColor="secondary"
            textColor="inherit"
            variant="fullwidth"
          > */}
          {/* <Tab
              label="Anasayfa"
              component={Link}
              to={routes[4]}
            /> */}
          {/* <Tab label="Anasayfa" to='/dashboard' component={Dashboard} /> */}
          {/* <Tab label="Anasayfa" onClick={() => {
              window.location.href = "/dashboard";
            }} /> */}
          {/* <Tab
              label="Teklifler"
              component={Link}
              to={routes[8]}
            /> */}
          {/* <Tab color='inherit'
              id='resources-button'
              aria-controls={open ? 'resources-menu' : undefined}
              aria-haspopup='true'
              aria-expanded={open ? 'true' : undefined}
              label='Sözleşmeler'
              component={Link}
              to={routes[4]}
              //onClick={handleClick}
              >
            </Tab> */}
          {/* <Tab
              label="Faturalar"
              component={Link}
              to={routes[7]}
            />
          </Tabs> */}
          <Button sx={{ marginLeft: "auto" }}>
          </Button>
          {user.firstName} {user.lastName}
          <Account />
          <LanguageSwitcher></LanguageSwitcher>
          {/* <Button sx={{ marginLeft: "10px" }} variant="contained" onClick={() => Logout()}>
              Çıkış Yap
            </Button> */}
          <Menu
            id='resources-menu'
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            MenuListProps={{
              'aria-labelledby': 'resources-button'
            }}>
            <Link to='/estateAgentContracts' style={{ color: "black" }}>
              <MenuItem>Emlak Sözleşmeleri</MenuItem>
            </Link>
            {/* <MenuItem onClick={() => {
              window.location.href = "/estateAgentContracts";
            }}>Emlak Sözleşmeleri</MenuItem> */}
            <Link to='/privateIntegratorContracts' style={{ color: "black" }}>
              <MenuItem>Özel Entegratör Sözleşmeleri</MenuItem>
            </Link>
            <Link to='/basicContracts' style={{ color: "black" }}>
              <MenuItem>Temel Sözleşmeler</MenuItem>
            </Link>
            {/* <Link to='/companyPrivateContracts' style={{ color: "black" }}>
              <MenuItem>Şirket Özel Sözleşmeleri</MenuItem>
            </Link> */}
            {/* <MenuItem onClick={() => {
              window.location.href = "/privateIntegratorContracts";
            }}>Özel Entegratör Sözleşmeleri</MenuItem> */}
          </Menu>
        </Toolbar>
      </AppBar>
    </HideOnScroll>

  )

  const adminIsAuthenticated = (

    <HideOnScroll>
      <AppBar position="static" color="inherit">
        <Toolbar>
          {/* <Sidebarr /> */}
          <Box>
            <Typography variant="h6">Diji Söz
              <span>   </span>
              <i class="fas fa-leaf"></i>
            </Typography>
          </Box>
          <Tabs sx={{ marginLeft: "auto" }}
            indicatorColor="secondary"
            textColor="inherit"
            variant="fullwidth"
          >

            <Tab label="Anasayfa"
              component={Link}
              to={routes[5]} />

            <Tab label="Şablon Oluştur"
              component={Link}
              to={routes[6]} />

          </Tabs>
          <Button sx={{ marginLeft: "auto" }}>
          </Button>
          {user.firstName} {user.lastName}
          <Account />
          {/* <Button sx={{ marginLeft: "10px" }} variant="contained" onClick={() => Logout()}>
              Çıkış Yap
            </Button> */}
        </Toolbar>
      </AppBar>
    </HideOnScroll>

  )

  let headerLinks;

  if (validToken && (user["role"] === "ROLE_COMPANY_REPRESENTATIVE" || user["role"] === "ROLE_USER")) {
    headerLinks = userIsAuthenticated;
  } else if (validToken && user["role"] === "ROLE_ADMIN") {

    headerLinks = adminIsAuthenticated;
    console.log(validToken);
    console.log(user["role"]);

  } else {
    headerLinks = userIsNotAuthenticated;
    console.log(validToken);
    console.log(user);
  }

  return (
    <>
      <div>
        {headerLinks}
      </div>
    </>
  );
}