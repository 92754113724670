import AddIcon from "@mui/icons-material/Add";
import ArchiveIcon from "@mui/icons-material/Archive";
import DeleteIcon from "@mui/icons-material/Delete";
import UpdateIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import { DataGrid, GridToolbarQuickFilter, trTR } from "@mui/x-data-grid";
import axios from "axios";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useSelector } from "react-redux";
import "../Layout/datatable.scss";
import PageHeader from "../Layout/PageHeader";
import Customer from "./Customer";

const userColumns = [
  { field: "title", headerName: "Ünvan", width: 200 },
  {
    field: "name",
    headerName: "Adı",
    width: 280,
  },
  {
    field: "identityNumber",
    headerName: "VKN / TCKN NO",
    width: 220,
  },
  {
    field: "phoneNumber",
    headerName: "Telefon Numarası",
    width: 150,
  },
  {
    field: "email",
    headerName: "e-Mail",
    width: 100,
  },
  {
    field: "taxAdminstration",
    headerName: "Vergi Dairesi",
    with: 100,
  },
];

pdfMake.vfs = pdfFonts.pdfMake.vfs;

function NoRowsOverlay() {
  return (
    <Stack height="100%" alignItems="center" justifyContent="center">
      Müşteri Kaydı Bulunmamaktadır
    </Stack>
  );
}

const Customers = () => {
  const [data, setData] = useState([]);
  const user = useSelector((state) => state.security.user);
  const [deletedRow, setDeletedRow] = useState({});
  const [deleteShow, setDeleteShow] = useState(false);
  const [updatedRow, setUpdatedRow] = useState(null);
  const [addNew, setAddNew] = useState(false);
  const ownerIdentityNumber = user.company
    ? user.company.identityNumber
    : user.identityNumber;

  useEffect(() => {
    customerData();
  }, []);

  const resetForm = () => {
    customerData();
    setAddNew(false);
    setUpdatedRow(null);
    setDeletedRow(null);
    setDeleteShow(false);
  };
  async function customerData() {
    await axios
      .get(`api/customer/get-all/${ownerIdentityNumber}`)
      .then((res) => {
        setData(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function handleDelete() {
    let rowDeleted = { ...deletedRow, isDeleted: true };

    axios
      .post(`api/customer/save`, rowDeleted)
      .then((res) => {
        if (res.status === 200) {
          resetForm();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const actionColumn = [
    {
      field: "action",
      headerName: "",
      width: 280,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <Modal show={deleteShow} onHide={() => setDeleteShow(false)}>
              <Modal.Body>Müşteri Silinsin mi?</Modal.Body>
              <Modal.Footer>
                <Button
                  variant="secondary"
                  onClick={() => setDeleteShow(false)}
                >
                  Hayır
                </Button>
                <Button variant="primary" onClick={(e) => handleDelete()}>
                  Evet
                </Button>
              </Modal.Footer>
            </Modal>

            <Tooltip title="Güncelle">
              <IconButton
                onClick={() => {
                  setUpdatedRow(params.row);
                  setAddNew(false);
                }}
              >
                <UpdateIcon color="primary" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Sil">
              <IconButton
                onClick={() => {
                  setDeleteShow(true);
                  setDeletedRow(params.row);
                }}
              >
                <DeleteIcon color="inherit" />
              </IconButton>
            </Tooltip>
          </div>
        );
      },
    },
  ];
  return (
    <>
      <PageHeader
        title="Müşteri Bilgileri"
        subTitle="Müşteri işlemlerini gerçekleştirebilirsiniz."
        icon={<ArchiveIcon fontSize="large" color="action" />}
      />
      {addNew && !updatedRow ? (
        <div className="home">
          <div className="homeContainer">
            <div className="listContainer">
              <div className="datatable">
                <div className="datatableTitle">Müşteri Bilgileri</div>
                <Customer resetForm={resetForm} />
              </div>
            </div>
          </div>
        </div>
      ) : null}
      {updatedRow && !addNew ? (
        <div className="home">
          <div className="homeContainer">
            <div className="listContainer">
              <div className="datatable">
                <div className="datatableTitle">Müşteri Bilgileri</div>
                <Customer customer={updatedRow} resetForm={resetForm} />
              </div>
            </div>
          </div>
        </div>
      ) : null}
      <div className="home">
        <div className="homeContainer">
          <div className="listContainer">
            <div className="datatable">
              <div className="datatableTitle">
                Müşteriler
                <Tooltip title="Ekle">
                  <IconButton
                    onClick={() => {
                      setUpdatedRow(null);
                      setAddNew(true);
                    }}
                  >
                    <AddIcon color="primary" />
                  </IconButton>
                </Tooltip>
              </div>
              <DataGrid
                //className="datagrid"
                rows={data}
                getRow={(row) => row}
                columns={userColumns.concat(actionColumn)}
                localeText={trTR.components.MuiDataGrid.defaultProps.localeText}
                //components={{ NoRowsOverlay }}
                //pageSize={8}
                //onCellClick={handleCellClick}
                //rowsPerPageOptions={[10]}
                components={{
                  Toolbar: GridToolbarQuickFilter,
                  NoRowsOverlay,
                  /* slotProps: {
                        toolbar: {
                          showQuickFilter: true,
                        },
                      } */
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Customers;
