import { GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";
import classnames from "classnames";
import jwtDecode from "jwt-decode";
import PropTypes from "prop-types";
import React, { Component } from "react";
import FacebookLogin from "react-facebook-login";
import ReCAPTCHA from "react-google-recaptcha";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { login, registerLoginExternal } from "../../actions/securityActions";
import { RegisterFrom } from "../../actions/types";
import "./../UserManagement/SocialLoginButton.css";
import "./Login.css";
class Login extends Component {
  constructor() {
    super();
    this.state = {
      username: "",
      password: "",
      errors: {},
      captcha: null,
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.googleLoginSuccess = this.googleLoginSuccess.bind(this);
    this.googleLoginFailure = this.googleLoginFailure.bind(this);
    this.responseFacebook = this.responseFacebook.bind(this);
    this.handleCaptchaChange = this.handleCaptchaChange.bind(this);
    this.recaptchaRef = React.createRef(); 
  }

  componentDidMount() {
    if (
      this.props.security.validToken &&(
      this.props.security.user["role"] === "ROLE_COMPANY_REPRESENTATIVE" ||
      this.props.security.user["role"] === "ROLE_USER"
    )
    ) {
      this.props.history.push("/dashboard");
    }
    if (
      this.props.security.validToken &&
      this.props.security.user["role"] === "ROLE_ADMIN"
    ) {
      this.props.history.push("/adminDashboard");
    }
  }

  componentWillReceiveProps(nextProps) {
    console.log(nextProps);
    if (
      nextProps.security.validToken &&(
      nextProps.security.user["role"] === "ROLE_COMPANY_REPRESENTATIVE" ||
      nextProps.security.user["role"] === "ROLE_USER")
    ) {
      this.props.history.push("/dashboard");
    }

    if (
      nextProps.security.validToken &&
      nextProps.security.user["role"] === "ROLE_ADMIN"
    ) {
      this.props.history.push("/adminDashboard");
    }

    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }
  }

  responseFacebook = (response) => {
    try {
      let names = response.name.split(" ");
      let name = "";
      let lastName = "";
      if (names.length > 1) {
        lastName = names[names.length - 1];
        names.splice(names.length - 1, 1);
      }
      name = names.join(" ");

      const newUser = {
        firstName: name,
        lastName: lastName,
        username: response.email,
        registerFrom: RegisterFrom.Facebook,
        thirdPartyUserId: response.userID,
        phoneNumber: "",
        password: "",
        confirmPassword: "",
      };

      this.props.registerLoginExternal(newUser);
    } catch (ex) {
      this.setState({
        errors: { facebook: "Facebook ile girişte hata oluştu" },
      });
    }
  };

  googleLoginSuccess = (response) => {
    try {
      let data = jwtDecode(response.credential);
      console.log(data.sub);
      const newUser = {
        firstName: data.given_name,
        lastName: data.family_name,
        username: data.email,
        registerFrom: RegisterFrom.Google,
        thirdPartyUserId: data.sub,
        phoneNumber: "",
        password: "",
        confirmPassword: "",
      };
      this.props.registerLoginExternal(newUser);
    } catch (ex) {
      this.setState({ errors: { google: "Google ile girişte hata oluştu" } });
    }
  };

  googleLoginFailure = () => {
    this.setState({ errors: { google: "Google ile girişte hata oluştu" } });
  };

  onSubmit(e) {
   
    if(this.state.captcha === null) {
      this.setState({errors: {message: "Lütfen robot olmadığınızı doğrulayın."}})
      return;
    }

    e.preventDefault();
    const LoginRequest = {
      username: this.state.username,
      password: this.state.password,
      captcha: this.state.captcha
    };

    this.props.login(LoginRequest);
    this.recaptchaRef.current.reset();
    this.setState({captcha: null});
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleCaptchaChange = (value) => {
    this.setState({captcha: value});
    if(value !== null) {
      this.setState({errors: {captcha: null}})
    }
  }

  render() {
    const { errors } = this.state;
    return (
      <section className="forms top">
        <div className="container">
          
          <div className="sign-box">
          <img style={{
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
        //width: '50%', // Gerekirse boyutu ayarlayın
      }} height={150} width={250} src="../images/dijisoz.png" alt="Home" />
            <p>
              Hesabınıza giriş 
              yapmak için Dijisöz
              uygulamasına kayıtlı eposta ve şifrenizi 
              giriniz.
            </p>
            <form action="" onSubmit={this.onSubmit}>
              <div className="form-group">
                <input
                  type="text"
                  className={classnames("form-control form-control-lg", {
                    "is-invalid": errors.username,
                  })}
                  placeholder="Email Adresi"
                  name="username"
                  value={this.state.username}
                  onChange={this.onChange}
                />
                {/* {errors.username && (
                  <div className="invalid-feedback">{errors.username}</div>
                )} */}
              </div>
              <div className="form-group">
                <input
                  type="password"
                  className={classnames("form-control form-control-lg", {
                    "is-invalid": errors.password,
                  })}
                  placeholder="Şifre"
                  name="password"
                  value={this.state.password}
                  onChange={this.onChange}
                />
                {errors.message && (
                  <div style={{ color: "red" }}>{errors.message}</div>
                )}
                {errors.captcha && (
                  <div style={{ color: "red" }}>{errors.captcha}</div>
                )}
              </div>
              {/* <div className='flex_space'>
                <div className='flex'>
                  <input type='checkbox' />
                  <label>Beni Hatırla</label>
                </div>
                <div className='flex'>
                  <span>Şifremi Unuttum</span>
                </div>
              </div> */}
              <ReCAPTCHA
                ref={this.recaptchaRef}
                sitekey={process.env.REACT_APP_GOOGLE_CAPTCHA_SECRET}
                onChange={this.handleCaptchaChange}
              />
              <button disabled={this.state.captcha === null} type="submit" className="primary-btn">
                Giriş Yap
              </button>
              <p>
                Hesabınız yok mu? <Link to="/register">Kayıt Ol!</Link>
              </p>
              <p>
                Şifrenizi mi unuttunuz? <Link to="/resetPassword">Şifre Sıfırla!</Link>
              </p>
              <p>
                Müşterimiz olmak ister misiniz? <Link to="/companySubscription">Müşteri ol!</Link>
              </p>
              <GoogleOAuthProvider
                  clientId={process.env.REACT_APP_GOOGLE_CLIENTID}
                >
              <div className="social-login-buttons">
                
                  <GoogleLogin
                  clientId={process.env.REACT_APP_GOOGLE_CLIENTID}
                    onSuccess={this.googleLoginSuccess}
                    onFailure={this.googleLoginFailure}
                    buttonText="Google ile Giriş Yap"
                    render={(renderProps) => (
                      <button
                          className="google-btn"
                          onClick={renderProps.onClick}
                          disabled={renderProps.disabled}
                      >
                          <img
                              src="https://upload.wikimedia.org/wikipedia/commons/5/53/Google_%22G%22_Logo.svg" // Google logosu URL
                              alt="Google Logo"
                          />
                          <span className="google-btn-text">Google ile Giriş Yap</span>
                      </button>
                  )}
                  />
                
                {errors.google && (
                  <div style={{ color: "red" }}>{errors.google}</div>
                )}
              </div>
              </GoogleOAuthProvider>

              <div className="social-login-buttons">
                <FacebookLogin
                  appId="1413350096049631"
                  autoLoad={false}
                  fields="name,email,picture"
                  callback={this.responseFacebook}
                  cssClass="facebook-button"
                  textButton="Facebook ile Giriş"
                />
                {errors.facebook && (
                  <div style={{ color: "red" }}>{errors.facebook}</div>
                )}
              </div>
            </form>
          </div>
        </div>
      </section>
    );
  }
}

Login.propTypes = {
  login: PropTypes.func.isRequired,
  registerLoginExternal: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  security: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  security: state.security,
  errors: state.errors,
});

export default connect(mapStateToProps, { login, registerLoginExternal })(
  Login
);
