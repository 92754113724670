import { TextField } from "@material-ui/core";
import Stack from "@mui/material/Stack";
import { DataGrid, trTR } from "@mui/x-data-grid";
import axios from "axios";
import { UserPlus } from "lucide-react";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useHistory } from "react-router-dom";
import { DocumentStatus } from "../../actions/types";
import { convertHtmlToPdf } from "../../util/pdf/PdfConverter";
import "./datatable.scss";
import { userColumns } from "./datatablersourcetemp";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

function NoRowsOverlay() {
  return (
    <Stack height="100%" alignItems="center" justifyContent="center">
      Sözleşme Kaydı Bulunmamaktadır
    </Stack>
  );
}

const ReceiveDataTable = (props) => {
  const [data, setData] = useState([]);
  const [url, setUrl] = useState(null);
  const [show, setShow] = useState(false);
  const [approveShow, setApproveShow] = useState(false);
  const handleApproveClose = () => setApproveShow(false);
  const [rejectShow, setRejectShow] = useState(false);
  const [rejectReason, setRejectReason] = useState("");
  const history = useHistory();
  const handleRejectClose = () => {
    setRejectShow(false);
    setRejectReason("");
  };

  async function contractData(identifierNumber, phoneNumber) {
    await axios
      .get(`api/contract-query-service/contract-receiver/${identifierNumber}/$(phoneNumber)`)
      .then((res) => {
        setData(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const handleCustomerConversion = (contractDatam) => {
    // Transform contract data to match CompanySubscription form structure
    const contractData = JSON.parse(contractDatam);
    console.log("log 1")
    console.log(contractData.firmName)
    console.log(contractData);
    const subscriptionData = {
      company: {
        name: contractData.firmName,
        type: contractData.firmType,
        publicOrPrivate: contractData.publicOrPrivateFirm,
        identityNumber: contractData.taxID,
        title: contractData.title,
        phoneNumber: contractData.phoneNumber,
        email: contractData.email,
        taxOffice: contractData.taxAdminstration,
        city: contractData.city,
        district: contractData.district,
        address: contractData.address,
        iban: contractData.IBAN
      },
      representative: {
        id: null,
        firstName: '',
        lastName: '',
        identityNumber: '',
        username: '',
        phoneNumber: '',
        password: '',
        confirmPassword: ''
      },

      captcha: ''
    };

    // Store the data in sessionStorage for the next component
    sessionStorage.setItem('subscriptionFormData', JSON.stringify(subscriptionData));
    console.log("log 2")
    console.log(subscriptionData)
    // Navigate to the subscription form
    history.push('/companysubscription');
  };


  useEffect(() => {
    contractData(props.identifierNumber, props.phoneNumber);
  }, [props.identifierNumber, props.phoneNumber]);

  const create = (docDefinition) => {
    const pdfDocGenerator = pdfMake.createPdf(docDefinition);
    pdfDocGenerator.getBlob((blob) => {
      const url = URL.createObjectURL(blob);
      setUrl(url);
    });
  };

  async function showModal(contractId, show) {
    if (show) {
      setShow(false);
      return;
    }
  
    try {
      const response = await axios.get(`api/contract/get-document-file/${contractId}`);
      
      if (response.data.isSigned) {
        setUrl(response.data.preSignedUrl);
        setShow(true);
        return;
      }
  
      const content = response.data.htmlContent;
      
      // Sadece PDF Blob'ı al
      const pdfBlob = await convertHtmlToPdf(content);
      
      // Blob'dan URL oluştur - bu kısım görüntüleme mantığına ait
      const pdfUrl = URL.createObjectURL(pdfBlob);
      
      setUrl(pdfUrl);
      setShow(true);
  
    } catch (error) {
      console.error('Error in contract modal:', error);
    }
  }


  async function handleApprove(contractId, status) {
    let contractStatus = {
      id: contractId,
      status: DocumentStatus.Approve,
    };
    await axios
      .post(`api/contract-query-service/update-contract-status`, contractStatus)
      .then((res) => {
        contractData(props.identifierNumber);
        handleApproveClose();
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function handleReject(contractId, status) {
    let contractStatus = {
      id: contractId,
      status: DocumentStatus.Reject,
      rejectReason: rejectReason,
    };
    await axios
      .post(`api/contract-query-service/update-contract-status`, contractStatus)
      .then((res) => {
        contractData(props.identifierNumber);
        handleRejectClose();
        setRejectReason("");
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function handleTextareaChange(e) {
    setRejectReason(e.target.value);
    //console.log("red sebebi : " +rejectReason);
  }

  const actionColumn = [
    {
      field: "action",
      headerName: "",
      width: 250,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <button
              onHide={() => setShow(false)}
              onClick={() =>
                showModal(params.row.contractType, params.row.contractId, show)
              }
              className="viewButton"
            >
              Görüntüle
              <Modal show={show}>
                <div onClick={() => setShow(false)}></div>
                <div style={{ position: "fixed" }}>
                  {url && (
                    <object
                      style={{
                        width: "200%",
                        height: "75vh",
                      }}
                      data={url}
                      type="application/pdf"
                    >
                      <embed
                        frameborder="0"
                        width="300%"
                        height="500px"
                        src={url}
                        type="application/pdf"
                      />
                    </object>
                  )}
                </div>
              </Modal>
            </button>
            <button
              onHide={handleApproveClose}
              onClick={() => setApproveShow(true)}
              className="approveButton"
            >
              Onayla
            </button>
            <Modal show={approveShow} onHide={handleApproveClose}>
              <Modal.Body>Sözleşme Onaylanacak emin misiniz?</Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleApproveClose}>
                  Hayır
                </Button>
                <Button
                  variant="primary"
                  onClick={(e) => handleApprove(params.row.contractId)}
                >
                  Evet
                </Button>
              </Modal.Footer>
            </Modal>
            <button
              onHide={handleRejectClose}
              onClick={() => setRejectShow(true)}
              className="rejectButton"
            >
              Reddet
            </button>
            <button
              onClick={() => handleCustomerConversion(params.row.content)}
              className="customerButton"
              title="Müşteri Ol"
            >
              <UserPlus size={16} />
            </button>
            <Modal show={rejectShow} onHide={handleRejectClose}>
              <Modal.Body>
                <TextField
                  id="outlined-textarea"
                  label="Red Nedeni"
                  placeholder="Lütfen Red Nedeninizi bu kısma metin olarak ekleyiniz. "
                  multiline
                  inputProps={{
                    style: {
                      height: "150px",
                    },
                  }}
                  fullWidth
                  value={rejectReason}
                  onChange={handleTextareaChange}
                />
                <p>Sözleşme Reddolacak emin misiniz?</p>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleRejectClose}>
                  Hayır
                </Button>
                <Button
                  variant="primary"
                  onClick={(e) => handleReject(params.row.contractId)}
                >
                  Evet
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        );
      },
    },
  ];
  return (
    <div className="datatable">
      <div className="datatableTitle">
        Sözleşmeler
        {/* <Link to="/users/new" className="link">
                    Add New
                </Link> */}
      </div>
      <DataGrid
        className="datagrid"
        rows={data}
        getRowId={(row) => row.contractId}
        columns={userColumns.concat(actionColumn)}
        localeText={trTR.components.MuiDataGrid.defaultProps.localeText}
        components={{ NoRowsOverlay }}
        pageSize={8}
        rowsPerPageOptions={[9]}
      />
    </div>
  );
};

export default ReceiveDataTable;
