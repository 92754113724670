export const CostItemUnit = [
    { label: 'ADET', value: 'ADET' },
    { label: 'KİLOGRAM', value: 'KİLOGRAM' },
    { label: 'GRAM', value: 'GRAM' },
    { label: 'METRE', value: 'METRE' },
    { label: 'LİTRE', value: 'LİTRE' },
    { label: 'PAKET (Packet)', value: 'PAKET_PACKET' },
    { label: 'PAKET (Pack)', value: 'PAKET_PACK' },
    { label: 'KUTU', value: 'KUTU' },
    { label: 'METREKARE', value: 'METREKARE' },
    { label: 'SANTİMETRE', value: 'SANTİMETRE' },
    { label: 'METREKÜp', value: 'METREKÜp' },
    { label: 'RULO', value: 'RULO' },
    { label: 'SET', value: 'SET' },
    { label: 'SANTİMETREKÜP', value: 'SANTİMETREKÜP' }
  ];