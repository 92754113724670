import { Button, Typography } from "@material-ui/core";
import { Box } from "@mui/material";
import axios from "axios";
import React, { useCallback, useEffect, useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { FormProvider, useForm, useFormContext } from "react-hook-form";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import NumericInput from "../NumericInput";
import PhoneInput from "../PhoneInput";
import "./ContractQuery.css";
import UsersViewAllContract from "./UsersViewAllContract";

const UserInformations = () => {
  const {
    control,
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <>
      <PhoneInput
        control={control}
        name="phoneNumber"
        error={errors.phoneNumber && { message: 'Telefon numaranız 10 karakter olmalıdır.' }}
      />
      <NumericInput
        control={control}
        name="identifierNumber"
        label="TC veya Vergi Kimlik Numarası"
        placeholder="TC veya Vergi Kimlik Numarası Giriniz"
        rules={{
          required: "Bu alan zorunludur",
          validate: (value) => {
            if (value.length !== 10 && value.length !== 11) {
              return "10 veya 11 karakter giriniz";
            }
            return true;
          }
        }}
       />
    </>
  );
};

const SMSInformation = () => {
  const {
    control,
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <>
      <NumericInput
        control={control}
        name="OTPCode"
        label="SMS Kodu"
        placeholder="SMS Kodu"
        rules={{
          required: "SMS kodu zorunludur",
          maxLength: { value: 6, message: "SMS kodu 6 karakter olmalıdır" },
          minLength: { value: 6, message: "SMS kodu 6 karakter olmalıdır." }
        }}
      />
    </>
  );
};

function getStepContent(step) {
  switch (step) {
    case 0:
      return <UserInformations />;
    case 1:
      return <SMSInformation />;
    default:
      return "unknown step";
  }
}

function getSteps() {
  return ["Kullanıcı Bilgileri", "SMS Kod Girişi"];
}

const ContractQuery = () => {
  const token = useSelector((state) => state.security.validToken);
  const methods = useForm({
    defaultValues: {
      phoneNumber: "",
      identifierNumber: "",
      OTPCode: "",
    },
  });
  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();
  const [SMSConfirmation, setSMSConfirmation] = useState(false);
  const recaptchaRef = useRef();
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    console.log("Component rendered. Active step:", activeStep);
  }, [activeStep]);

  const executeRecaptcha = useCallback(async () => {
    if (recaptchaRef.current) {
      try {
        const token = await recaptchaRef.current.executeAsync();
        return token;
      } catch (error) {
        console.error("reCAPTCHA execution failed:", error);
        return null;
      }
    }
    return null;
  }, []);

  const handleNext = useCallback(async (data) => {
    console.log("handleNext called with data:", data);
    setIsSubmitting(true);

    try {
      const captchaToken = await executeRecaptcha();
      console.log(captchaToken)
      if (activeStep < steps.length - 1) {
        console.log("Sending OTP request...");
        const response = await axios.post(
          `api/contract-query-service/send-otp`,
          {
            phoneNumber: data.phoneNumber.replace(/\D/g, ''),
            identifierNumber: data.identifierNumber,
            captcha: captchaToken
          }
        );
        console.log("OTP request response:", response);
        setActiveStep(activeStep + 1);
        toast.success("SMS Kodu Gönderildi ...", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else if (activeStep === steps.length - 1) {
        console.log("Validating OTP...");
        const res = await axios.post(
          `api/contract-query-service/validate-otp`,
          {
            otpCode: data.OTPCode,
            identifierNumber: data.identifierNumber,
            captcha: captchaToken
          }
        );
        console.log("OTP validation response:", res);
        if (res.status === 200) {
          setSMSConfirmation(true);
          setActiveStep(activeStep + 1);
          toast.success("Giriş Başarılı", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      }
    } catch (error) {
      console.error("Error in handleNext:", error);
      toast.error(error.response?.data?.message || error.message || "Bir hata oluştu", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } finally {
      setIsSubmitting(false);
    }
  }, [activeStep, steps.length, executeRecaptcha]);

  const onSubmit = async (data) => {
    if (!isSubmitting) {
      await handleNext(data);
    }
  };

  console.log("Rendering component. Active step:", activeStep);

  return (
    <>
      {token ? (
        <Redirect to="/dashboard" />
      ) : (
        <>
          {activeStep === steps.length && SMSConfirmation ? (
            <div>
              <UsersViewAllContract
                contractInformations={methods.getValues()}
              />
            </div>
          ) : (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: '100vh',
              }}
            >
             
              <section className="form top" style={{ width: '25%' }}>
              <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                      }}
                    >
                <img style={{
                  display: 'block',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  //width: '50%', // Gerekirse boyutu ayarlayın
                }} height={150} width={250} src="../images/dijisoz.png" alt="Home" />
                <Typography component="h1" variant="h5">
                        Sözleşme Sorgulama Formu
                      </Typography>
                Dijisoz uygulaması üzerinden gönderilen belgelerinizi bu ekrandan sorgulayabilirsiniz.
              </Box>
                <FormProvider {...methods}>
                  <form onSubmit={methods.handleSubmit(onSubmit)}>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                      }}
                    >
                      {/* <Avatar sx={{ m: 1 }}>
                        <LockOutlinedIcon />
                      </Avatar> */}
                      
                      <Box sx={{ mt: 1, width: '100%' }}>
                        {getStepContent(activeStep)}
                        <Button
                          color="primary"
                          type="submit"
                          fullWidth
                          variant="contained"
                          sx={{ mt: 4, mb: 2 }}
                          disabled={isSubmitting}
                        >
                          {activeStep === steps.length - 1 ? "Kodu Giriniz" : "Sorgula"}
                        </Button>
                      </Box>
                    </Box>
                  </form>
                </FormProvider>
              </section>
            </Box>
          )}
          <ReCAPTCHA
            ref={recaptchaRef}
            size="invisible"
            sitekey={process.env.REACT_APP_GOOGLE_CAPTCHA_SECRET}
          />
          <ToastContainer />
        </>
      )}
    </>
  );
};

export default ContractQuery;