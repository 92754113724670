import { Box } from '@mui/material';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import React, { useState } from "react";
import Table from "react-bootstrap/Table";
import "react-toastify/dist/ReactToastify.css";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const BasicContractTransform = (props) => {
  const [basicContractType] = useState(
    props.basicContractInformations["basicContractType"] === "DİĞER"
      ? props.basicContractInformations["contractOtherTypeValue"]
      : props.basicContractInformations["basicContractType"]
  );
  const [contractStartDate] = useState(
    props.basicContractInformations["contractStartDate"]
  );
  const [contractFinishDate] = useState(
    props.basicContractInformations["contractFinishDate"]
  );
  //const [contractOtherTypeValue, setContractOtherTypeValue] = useState(props.basicContractInformations["contractOtherTypeValue"]);
  const [firmName] = useState(props.basicContractInformations["firmName"]);
  const [firmType] = useState(props.basicContractInformations["firmType"]);
  const [publicOrPrivateFirm] = useState(
    props.basicContractInformations["publicOrPrivateFirm"]
  );
  const [taxID] = useState(props.basicContractInformations["taxID"]);
  const [title] = useState(props.basicContractInformations["title"]);
  const [phoneNumber] = useState(
    props.basicContractInformations["phoneNumber"]
  );
  const [email] = useState(props.basicContractInformations["email"]);
  const [taxAdminstration] = useState(
    props.basicContractInformations["taxAdminstration"]
  );
  const [city] = useState(props.basicContractInformations["city"]);
  const [district] = useState(props.basicContractInformations["district"]);
  const [address] = useState(props.basicContractInformations["address"]);
  const [basicContractTopic] = useState(
    props.basicContractInformations["basicContractTopic"]
  );
  const [contractFee] = useState(
    props.basicContractInformations["contractFee"]
  );
  const [currency] = useState(props.basicContractInformations["currency"]);
  const [contractPeriod] = useState(
    props.basicContractInformations["contractPeriod"]
  );
  const [contractFeeUpdateMetrics] = useState(
    props.basicContractInformations["contractFeeUpdateMetrics"]
  );
  const [feeUpdateRate] = useState(
    props.basicContractInformations["feeUpdateRate"]
  );
  const [feeUpdate] = useState(props.basicContractInformations["feeUpdate"]);
  //const [feeUpdateCurrency, setFeeUpdateCurrency] = useState(props.basicContractInformations["feeUpdateCurrency"]);
  const [privacy] = useState(props.basicContractInformations["privacy"]);
  const [criminalSanctions] = useState(
    props.basicContractInformations["criminalSanctions"]
  );
  //const [payDay, setPayDay] = useState(props.basicContractInformations["payDay"]);
  const [paymentDate] = useState(
    props.basicContractInformations["paymentDate"]
  );
  //const [IBAN] = useState(props.basicContractInformations["IBAN"]);
  const [invoiceDelayPaymentRate] = useState(
    props.basicContractInformations["invoiceDelayPaymentRate"]
  );
  const [paymentType] = useState(
    props.basicContractInformations["paymentType"]
  );
  const [currencyRatio] = useState(
    props.basicContractInformations["currencyRatio"]
  );

  return (
    <Box mb={2}> {/* 2 birim boşluk verir */}
      <Table stripped bordered hover>
        <th>Müşteri Bilgileri</th>
        <tbody>
          <tr>
            <td>Firma Adı</td>
            <td>{firmName}</td>
          </tr>
          <tr>
            <td>Firma Tipi</td>
            <td>{publicOrPrivateFirm}</td>
          </tr>
          <tr>
            <td>Firma Kişi Tipi</td>
            <td>{firmType}</td>
          </tr>
          <tr>
            <td>Vergi Kimlik Numarası</td>
            <td>{taxID}</td>
          </tr>
          <tr>
            <td>Vergi Dairesi İsmi</td>
            <td>{taxAdminstration}</td>
          </tr>
          <tr>
            <td>Unvan</td>
            <td>{title}</td>
          </tr>
          <tr>
            <td>Telefon Numarası</td>
            <td>{phoneNumber}</td>
          </tr>
          <tr>
            <td>Email</td>
            <td>{email}</td>
          </tr>
          <tr>
            <td>İl</td>
            <td>{city}</td>
          </tr>
          <tr>
            <td>İlçe</td>
            <td>{district}</td>
          </tr>
          <tr>
            <td>Adres</td>
            <td>{address}</td>
          </tr>
        </tbody>
        <th>Sözleşme İçeriği</th>
        <tbody>
          <tr>
            <td>Sözleşme Türü</td>
            <td>{basicContractType}</td>
          </tr>
          <tr>
            <td>Sözleşme Başlangıç Tarihi</td>
            <td>{contractStartDate}</td>
          </tr>
          <tr>
            <td>Sözleşme Bitiş Tarihi</td>
            <td>{contractFinishDate}</td>
          </tr>
          {/* <tr>
            <td>Sözleşme Yenileme Periyodu</td>
            <td>{contractPeriod}</td>
          </tr>
          <tr>
            <td>Sözleşme Bedeli Güncelleme Değeri</td>
            <td>{contractFeeUpdateMetrics}</td>
          </tr>
          <tr>
            <td>Güncelleme Bedeli</td>
            <td>
              {feeUpdateRate} {feeUpdate}
            </td>
          </tr> */}
          <tr>
            <td>Sözleşme Konusu</td>
            <td>{basicContractTopic}</td>
          </tr>
          <tr>
            <td>Gizlilik Koşulları</td>
            <td>{privacy}</td>
          </tr>
          <tr>
            <td>Cezai Yaptırımlar</td>
            <td>{criminalSanctions}</td>
          </tr>
        </tbody>
        <th>Ödeme Bilgileri</th>
        <tbody>
        <tr>
            <td>Sözleşme Bedeli</td>
            <td>
              {contractFee} {currency}
            </td>
          </tr>
          <tr>
            <td>Ödeme Tipi</td>
            <td>
              {paymentType}
            </td>
          </tr>
          <tr>
            <td>Kur</td>
            <td>
              {currencyRatio}
            </td>
          </tr>
          <tr>
            <td>Fatura Ödeme Vadesi</td>
            <td>{paymentDate}</td>
          </tr>
          {/* <tr>
            <td>Fatura Gecikme Vade Farkı</td>
            <td>{invoiceDelayPaymentRate}</td>
          </tr> */}
        </tbody>
      </Table>
      {/* <button onClick={(e) => create()} className="btn btn-danger btn-cli" type="button" id="button-addon2"><i className="far fa-file-pdf"></i> Sözleşmeyi İndir</button> */}
      {/* <span> </span>
            <button className="btn btn-success" onClick={handleShow} type="button">Gönder</button>
            <ToastContainer />
            <div>
                <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Firma Bilgileri</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{firmName}</Modal.Body>
                    <Modal.Body>Telefon: {phoneNumber} </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Kapat
                        </Button>
                        <Button variant="primary" onClick={(e) => sendEInvoiceContract()}>
                            Gönder
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div> */}
    </Box>
  );
};

export default BasicContractTransform;
