import { TextField } from "@material-ui/core";
import React from 'react';
import { Controller } from "react-hook-form";

const NumericInput = ({ control, name, label, placeholder, rules }) => {
  const handleNumericInput = (event) => {
    const result = event.target.value.replace(/\D/g, '');
    event.target.value = result;
  };

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field, fieldState: { error } }) => (
        <TextField
          {...field}
          label={label}
          placeholder={placeholder}
          variant="outlined"
          margin="normal"
          fullWidth
          onInput={handleNumericInput}
          error={!!error}
          helperText={error?.message}
        />
      )}
    />
  );
};

export default NumericInput;